import React, { useState, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { Box, TextField, Button, Typography, Paper, Link, CircularProgress } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import backgroundImage from '../../assets/images/start-sf.png';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { login, resetPassword } = useContext(AuthContext);
    const navigate = useNavigate();

    const inputStyle = {
        '& .MuiOutlinedInput-root': {
            borderRadius: '15px',
        },
    };

    const buttonStyle = {
        borderRadius: '25px',
        textTransform: 'none',
        fontSize: '16px',
        padding: '10px 0',
        boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
        transition: 'all 0.15s ease',
        '&:hover': {
            transform: 'translateY(-1px)',
            boxShadow: '0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)',
        },
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await login(email, password);
            navigate('/');
        } catch (error) {
            console.error('Error al iniciar sesión:', error);
            alert(`Error al iniciar sesión: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleForgotPassword = async () => {
        if (!email) {
            alert('Por favor, ingrese su correo electrónico');
            return;
        }
        setIsLoading(true);
        try {
            await resetPassword(email);
            alert('Se ha enviado un correo electrónico para restablecer su contraseña');
        } catch (error) {
            console.error('Error al restablecer la contraseña:', error);
            alert(`Error al restablecer la contraseña: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box sx={{ 
            position: 'relative',
            display: 'flex', 
            flexDirection: 'column', 
            minHeight: '100vh',
            alignItems: 'center', 
            justifyContent: 'center',
        }}>
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: '50%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                opacity: 0.2,
                zIndex: -1,
            }} />
            <Paper elevation={3} sx={{ 
                padding: 4, 
                width: '100%', 
                maxWidth: 400,
                backgroundColor: 'white',
                position: 'relative',
                zIndex: 1,
                borderRadius: '20px',
            }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
                    <img src={logo} alt="FitAI Labs Logo" style={{ maxWidth: '200px', height: 'auto' }} />
                </Box>
                <Typography variant="h5" component="h1" gutterBottom textAlign="center">
                    Iniciar Sesión
                </Typography>
                <form onSubmit={handleLogin}>
                    <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        sx={inputStyle}
                    />
                    <TextField
                        fullWidth
                        label="Contraseña"
                        type="password"
                        variant="outlined"
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        sx={inputStyle}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={isLoading}
                        sx={{ ...buttonStyle, mt: 3, mb: 2 }}
                    >
                        {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Iniciar Sesión'}
                    </Button>
                </form>
                <Typography variant="body2" align="center">
                    ¿Olvidaste tu contraseña?{' '}
                    <Link component="button" variant="body2" onClick={handleForgotPassword}>
                        Restablecer contraseña
                    </Link>
                </Typography>
                <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                    ¿No tienes una cuenta?{' '}
                    <Link component={RouterLink} to="/register">
                        Regístrate
                    </Link>
                </Typography>
            </Paper>
        </Box>
    );
};

export default Login;
