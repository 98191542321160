import { getFirestore, doc, deleteDoc, updateDoc, getDoc } from 'firebase/firestore';

const deleteClient = async (userDetails, clientId) => {
    const db = getFirestore();

    try {
        let clientDocRef;

        if (userDetails.tipo_profesional === 'entrenador_independiente') {
            clientDocRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', clientId);
        } else if (['propietario_centro', 'entrenador_centro'].includes(userDetails.tipo_profesional)) {
            if (!userDetails.IDBO) {
                throw new Error('ID del Centro (IDBO) no disponible');
            }
            clientDocRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', clientId);
        } else {
            throw new Error("Tipo de profesional no reconocido");
        }

        // Obtener el documento del cliente para extraer el uidcliente
        const clientSnapshot = await getDoc(clientDocRef);
        if (!clientSnapshot.exists()) {
            throw new Error("El documento del cliente no existe");
        }
        const clientData = clientSnapshot.data();
        const uidcliente = clientData.uidcliente;

        if (!uidcliente) {
            throw new Error("uidcliente no encontrado en el documento del cliente");
        }

        // Actualizar el documento userDetails del cliente
        const clientUserDetailsRef = doc(db, 'userDetails', uidcliente);
        await updateDoc(clientUserDetailsRef, {
            usuarioprueba: false,
            tiene_entrenador: false,
            solicitud_aceptada: false,
            en_solicitud: false,
            IDPT: null,
            IDBO: null
        });

        // Eliminar el documento del cliente
        await deleteDoc(clientDocRef);

        console.log("Cliente eliminado y userDetails actualizado con éxito");
    } catch (error) {
        console.error("Error al eliminar el cliente:", error);
        throw error;
    }
};

export default deleteClient;
