import React, { useState, useEffect } from 'react';
import { 
    Grid, Typography, Paper, Box, CircularProgress, Alert,
    Card, CardContent, Chip, IconButton
} from '@mui/material';
import { LocalizationProvider, DateCalendar } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import { format } from 'date-fns';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import TimerIcon from '@mui/icons-material/Timer';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import { FiActivity, FiClock, FiHeart, FiZap } from 'react-icons/fi';
import ActivityDetailModal from './modals/ActivityDetailModal';

const FitnessTracker = ({ client }) => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [activityData, setActivityData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const db = getFirestore();

    const fetchActivityData = async (date) => {
        if (!client?.uidcliente) return;

        setLoading(true);
        try {
            const startOfDay = new Date(date);
            startOfDay.setHours(0, 0, 0, 0);
            const endOfDay = new Date(date);
            endOfDay.setHours(23, 59, 59, 999);

            const actividadesRef = collection(db, 'Actividad');
            const qActividades = query(
                actividadesRef,
                where('usuario', '==', client.uidcliente),
                where('fecha', '>=', startOfDay),
                where('fecha', '<=', endOfDay)
            );

            const ejerciciosRef = collection(db, 'Ejercicio');
            const qEjercicios = query(
                ejerciciosRef,
                where('usuario', '==', client.uidcliente),
                where('fecha', '>=', startOfDay),
                where('fecha', '<=', endOfDay)
            );

            const [actividadesSnapshot, ejerciciosSnapshot] = await Promise.all([
                getDocs(qActividades),
                getDocs(qEjercicios)
            ]);

            const actividadesData = actividadesSnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    ...data,
                    tipo_actividad: data.actividad,
                    tiempo: data.fecha,
                    duracion: data.duracion,
                    calorias_quemadas: calcularCalorias(data.duracion, data.intensidad),
                    frecuencia_cardiaca: calcularFrecuenciaCardiaca(data.intensidad)
                };
            });

            const pasosTotales = ejerciciosSnapshot.docs.reduce((total, doc) => {
                const data = doc.data();
                return total + (data.pasos || 0);
            }, 0);

            const totales = actividadesData.reduce((acc, actividad) => ({
                calorias: acc.calorias + (actividad.calorias_quemadas || 0),
                duracion: acc.duracion + (actividad.duracion || 0),
                frecuencia_cardiaca: Math.max(acc.frecuencia_cardiaca, actividad.frecuencia_cardiaca || 0)
            }), { calorias: 0, duracion: 0, frecuencia_cardiaca: 0 });

            setActivityData({
                fecha: format(date, 'yyyy-MM-dd'),
                ...totales,
                pasos: pasosTotales,
                actividades: actividadesData
            });

        } catch (error) {
            console.error('Error al obtener actividades:', error);
            setError('Error al cargar las actividades físicas');
            setActivityData(null);
        } finally {
            setLoading(false);
        }
    };

    const calcularCalorias = (duracion, intensidad) => {
        const caloriasPorMinuto = {
            'Baja': 5,
            'Media': 7,
            'Alta': 10
        };
        return Math.round(duracion * (caloriasPorMinuto[intensidad] || 5));
    };

    const calcularFrecuenciaCardiaca = (intensidad) => {
        const frecuenciaBase = {
            'Baja': 100,
            'Media': 130,
            'Alta': 160
        };
        return frecuenciaBase[intensidad] || 100;
    };

    useEffect(() => {
        fetchActivityData(selectedDate);
    }, [selectedDate, client]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleActivityClick = (activity) => {
        setSelectedActivity(activity);
    };

    const renderFitnessInfo = () => {
        if (loading) {
            return (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <CircularProgress />
                </Box>
            );
        }

        if (error) {
            return (
                <Alert severity="error" sx={{ mt: 2 }}>
                    {error}
                </Alert>
            );
        }

        const actividadesValidas = activityData?.actividades?.filter(actividad =>
            actividad.tipo_actividad &&
            (actividad.calorias_quemadas || actividad.duracion || actividad.pasos)
        ) || [];

        if (actividadesValidas.length === 0) {
            return (
                <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'center', mt: 2 }}>
                    No hay registros de actividad física para este día.
                </Typography>
            );
        }

        const metricsInfo = [
            {
                label: 'Calorías Quemadas',
                value: '---',
                unit: 'kcal',
                icon: <FiZap size={24} />,
                color: '#FF5722',
                gradient: 'linear-gradient(135deg, #FF5722 0%, #FF9800 100%)',
                bgLight: 'rgba(255, 87, 34, 0.1)',
                comingSoon: true
            },
            {
                label: 'Duración Total',
                value: activityData.duracion,
                unit: 'min',
                icon: <FiClock size={24} />,
                color: '#2196F3',
                gradient: 'linear-gradient(135deg, #2196F3 0%, #4CAF50 100%)',
                bgLight: 'rgba(33, 150, 243, 0.1)'
            },
            {
                label: 'Pasos',
                value: activityData.pasos,
                unit: 'pasos',
                icon: <FiActivity size={24} />,
                color: '#4CAF50',
                gradient: 'linear-gradient(135deg, #4CAF50 0%, #8BC34A 100%)',
                bgLight: 'rgba(76, 175, 80, 0.1)'
            },
            {
                label: 'FC Máxima',
                value: '---',
                unit: 'bpm',
                icon: <FiHeart size={24} />,
                color: '#F44336',
                gradient: 'linear-gradient(135deg, #F44336 0%, #FF5722 100%)',
                bgLight: 'rgba(244, 67, 54, 0.1)',
                comingSoon: true
            }
        ];

        return (
            <Box sx={{ mt: 3 }}>
                <Grid container spacing={2} sx={{ mb: 4 }}>
                    {metricsInfo.map((metric) => (
                        <Grid item xs={6} key={metric.label}>
                            <Box
                                sx={{
                                    background: metric.bgLight,
                                    borderRadius: '16px',
                                    p: 2,
                                    height: '100%',
                                    position: 'relative',
                                    overflow: 'hidden',
                                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                    '&:hover': {
                                        transform: 'translateY(-5px)',
                                        boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                                        '& .metric-icon': {
                                            transform: 'scale(1.1)',
                                        }
                                    },
                                    '&::before': {
                                        content: '""',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '4px',
                                        height: '100%',
                                        background: metric.gradient
                                    }
                                }}
                            >
                                {metric.comingSoon && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            backdropFilter: 'blur(4px)',
                                            backgroundColor: 'rgba(255, 255, 255, 0.6)',
                                            zIndex: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: 'primary.main',
                                                fontWeight: 'bold',
                                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                                padding: '4px 8px',
                                                borderRadius: '4px',
                                                fontSize: '0.8rem'
                                            }}
                                        >
                                            Coming Soon
                                        </Typography>
                                    </Box>
                                )}
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mb: 1
                                }}>
                                    <Box
                                        className="metric-icon"
                                        sx={{
                                            background: metric.gradient,
                                            borderRadius: '12px',
                                            p: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            transition: 'transform 0.3s ease',
                                            mr: 1
                                        }}
                                    >
                                        {React.cloneElement(metric.icon, {
                                            style: { color: 'white' }
                                        })}
                                    </Box>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: 'text.secondary',
                                            fontWeight: 500
                                        }}
                                    >
                                        {metric.label}
                                    </Typography>
                                </Box>
                                <Box sx={{ pl: 1 }}>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontWeight: 'bold',
                                            background: metric.gradient,
                                            WebkitBackgroundClip: 'text',
                                            WebkitTextFillColor: 'transparent',
                                            display: 'inline-block'
                                        }}
                                    >
                                        {Math.round(metric.value) || '---'}
                                    </Typography>
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        sx={{
                                            ml: 0.5,
                                            color: 'text.secondary',
                                            fontWeight: 500
                                        }}
                                    >
                                        {metric.unit}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>

                <Typography variant="h6" sx={{ mb: 2 }}>Actividades del día</Typography>
                {actividadesValidas.map((actividad, index) => (
                    <Paper 
                        key={index} 
                        elevation={2} 
                        sx={{ 
                            mb: 2, 
                            overflow: 'hidden',
                            cursor: 'pointer',
                            transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                            '&:hover': {
                                transform: 'translateY(-2px)',
                                boxShadow: 3
                            }
                        }}
                        onClick={() => handleActivityClick(actividad)}
                    >
                        <Grid container alignItems="center">
                            <Grid item xs={12}>
                                <Box sx={{ p: 2 }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                        {actividad.tipo_actividad}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {actividad.tiempo ? format(actividad.tiempo.toDate(), 'HH:mm') : ''}
                                    </Typography>
                                    <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                        <Chip
                                            icon={<TimerIcon />}
                                            label={`${actividad.duracion} min`}
                                            size="small"
                                            color="primary"
                                        />
                                        {actividad.kgtotales > 0 && (
                                            <Chip
                                                icon={<FitnessCenterIcon />}
                                                label={`${actividad.kgtotales} kg`}
                                                size="small"
                                                color="secondary"
                                            />
                                        )}
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                ))}

                <ActivityDetailModal
                    open={Boolean(selectedActivity)}
                    onClose={() => setSelectedActivity(null)}
                    activity={selectedActivity}
                />
            </Box>
        );
    };

    return (
        <Paper sx={{ p: 3 }}>
            <Typography variant="h5" sx={{ mb: 3 }}>
                Registro de Actividad Física
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                        <DateCalendar
                            value={selectedDate}
                            onChange={handleDateChange}
                            sx={{
                                width: '100%',
                                '& .MuiPickersDay-root': {
                                    borderRadius: '50%',
                                },
                                '& .MuiPickersDay-today': {
                                    borderColor: 'primary.main',
                                },
                                '& .Mui-selected': {
                                    backgroundColor: 'primary.main',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: 'primary.dark',
                                    },
                                },
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={8}>
                    {renderFitnessInfo()}
                </Grid>
            </Grid>
        </Paper>
    );
};

export default FitnessTracker;

