import React, { useState, useRef, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { 
    Typography, Box, Paper, TextField, List, 
    ListItem, ListItemText, Avatar, IconButton, Fade,
    Dialog, DialogTitle, DialogContent, DialogActions, Button
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import GroupIcon from '@mui/icons-material/Group';
import { AuthContext } from '../../contexts/AuthContext';
import { db } from '../../config/firebaseConfig';
import { collection, query, orderBy, onSnapshot, addDoc, serverTimestamp, doc, getDoc } from 'firebase/firestore';

const Chat = () => {
    const { chatId } = useParams();
    const [message, setMessage] = useState('');
    const { userDetails } = useContext(AuthContext);
    const [messages, setMessages] = useState([]);
    const [chatInfo, setChatInfo] = useState(null);
    const messagesEndRef = useRef(null);
    const [openModal, setOpenModal] = useState(false);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        const fetchChatInfo = async () => {
            const chatDoc = await getDoc(doc(db, 'Chats', chatId));
            if (chatDoc.exists()) {
                const data = chatDoc.data();
                const isGroup = data.participantes.length > 2;
                const otherParticipant = data.participantes.find(p => p.uid !== userDetails.uid);
                setChatInfo({
                    isGroup,
                    name: isGroup ? 'Grupo' : otherParticipant.nombre,
                    photo_url: isGroup ? null : otherParticipant.photo_url
                });
            }
        };

        fetchChatInfo();

        const q = query(collection(db, 'Chats', chatId, 'messages'), orderBy('time'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const fetchedMessages = querySnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    ...data,
                    timestamp: data.time ? data.time.toDate().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) : 'Hora desconocida'
                };
            });
            setMessages(fetchedMessages);
        });

        return () => unsubscribe();
    }, [chatId, userDetails]);

    useEffect(scrollToBottom, [messages]);

    const handleSendMessage = async () => {
        if (message.trim()) {
            try {
                await addDoc(collection(db, 'Chats', chatId, 'messages'), {
                    senderId: userDetails.uid,
                    texto: message,
                    time: serverTimestamp(),
                    tipo: 'texto'
                });
                setMessage('');
            } catch (error) {
                console.error("Error al enviar el mensaje:", error);
                // Aquí puedes añadir alguna notificación al usuario si lo deseas
            }
        }
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <Box 
            sx={{ 
                flexGrow: 1, 
                height: 'calc(100vh - 64px)', 
                display: 'flex', 
                flexDirection: 'column',
                position: 'relative',
                backgroundColor: '#F0F4F8', // Un fondo azul muy claro
                margin: -3,
                padding: 3,
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <IconButton component={Link} to="/messages" sx={{ mr: 2, color: 'text.primary' }}>
                    <ArrowBackIcon />
                </IconButton>
                {chatInfo && (
                    chatInfo.isGroup ? (
                        <Avatar sx={{ mr: 2, width: 48, height: 48 }}>
                            <GroupIcon />
                        </Avatar>
                    ) : (
                        <Avatar src={chatInfo.photo_url} sx={{ mr: 2, width: 48, height: 48 }}>
                            {chatInfo.name[0]}
                        </Avatar>
                    )
                )}
                <Typography variant="h5">{chatInfo ? chatInfo.name : 'Cargando...'}</Typography>
            </Box>
            <Paper 
                elevation={3} 
                sx={{ 
                    flexGrow: 1, 
                    mb: 2, 
                    p: 2, 
                    overflowY: 'auto',
                    borderRadius: '30px',
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    boxShadow: '0 10px 30px rgba(0,0,0,0.1)',
                    position: 'relative',
                }}
            >
                {messages.length === 0 ? (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            variant="h5"
                            sx={{
                                color: 'rgba(0, 0, 0, 0.3)',
                                fontWeight: 'bold',
                            }}
                        >
                            Todavía no hay mensajes en este chat
                        </Typography>
                    </Box>
                ) : (
                    <List>
                        {messages.map((msg) => (
                            <Fade in={true} key={msg.id}>
                                <ListItem 
                                    sx={{ 
                                        flexDirection: msg.senderId === userDetails.uid ? 'row-reverse' : 'row',
                                        mb: 2,
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <Avatar 
                                        src={msg.senderId === userDetails.uid ? userDetails.photo_url : chatInfo?.photo_url}
                                        sx={{ 
                                            width: 36,
                                            height: 36,
                                            mr: msg.senderId === userDetails.uid ? 0 : 1, 
                                            ml: msg.senderId === userDetails.uid ? 1 : 0,
                                            mb: 1,
                                        }}
                                    >
                                        {msg.senderId === userDetails.uid ? (userDetails.name[0] || 'P') : (chatInfo?.name[0] || 'C')}
                                    </Avatar>
                                    <Paper 
                                        elevation={0} 
                                        sx={{ 
                                            maxWidth: '70%',
                                            p: '12px 16px',
                                            borderRadius: '18px',
                                            bgcolor: msg.senderId === userDetails.uid ? '#FFA726' : '#E0E0E0', // Naranja más atractivo
                                            color: msg.senderId === userDetails.uid ? '#FFFFFF' : '#333333',
                                            position: 'relative',
                                            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                                            '&::before': {
                                                content: '""',
                                                position: 'absolute',
                                                bottom: 0,
                                                [msg.senderId === userDetails.uid ? 'right' : 'left']: -8,
                                                width: 20,
                                                height: 20,
                                                backgroundColor: msg.senderId === userDetails.uid ? '#FFA726' : '#E0E0E0',
                                                borderRadius: '50%',
                                                zIndex: -1,
                                            },
                                        }}
                                    >
                                        <Typography variant="body1" sx={{ mb: 0.5, lineHeight: 1.4 }}>
                                            {msg.texto}
                                        </Typography>
                                        <Typography 
                                            variant="caption" 
                                            sx={{ 
                                                display: 'block', 
                                                textAlign: 'right',
                                                opacity: 0.8,
                                                fontSize: '0.7rem',
                                            }}
                                        >
                                            {msg.timestamp}
                                        </Typography>
                                    </Paper>
                                </ListItem>
                            </Fade>
                        ))}
                        <div ref={messagesEndRef} />
                    </List>
                )}
            </Paper>
            <Paper 
                component="form" 
                sx={{ 
                    p: '2px 4px', 
                    display: 'flex', 
                    alignItems: 'center', 
                    borderRadius: '30px',
                    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                    backgroundColor: 'white',
                }}
            >
                <IconButton sx={{ p: '10px' }} aria-label="adjuntar archivo" onClick={handleOpenModal}>
                    <AttachFileIcon />
                </IconButton>
                <TextField
                    fullWidth
                    variant="standard"
                    placeholder="Escribe un mensaje..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
                    onKeyUp={(e) => e.key === 'Enter' && !e.shiftKey && handleSendMessage()}
                    InputProps={{
                        disableUnderline: true,
                    }}
                    sx={{ ml: 1, flex: 1 }}
                />
                <IconButton 
                    color="primary" 
                    sx={{ p: '10px' }} 
                    aria-label="enviar mensaje"
                    onClick={handleSendMessage}
                >
                    <SendIcon />
                </IconButton>
            </Paper>
            <Dialog open={openModal} onClose={handleCloseModal}>
                <DialogTitle>Funcionalidad en desarrollo</DialogTitle>
                <DialogContent>
                    <Typography>
                        Estamos trabajando en esta funcionalidad. Pronto podrás adjuntar archivos a tus mensajes.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Chat;