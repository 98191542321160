import React, { useState, useEffect } from 'react';
import { 
    Typography, Box, Paper, List, ListItem, ListItemText, 
    ListItemAvatar, Avatar, IconButton, Chip, Modal, Button, Divider
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import clientsBackground from '../../assets/images/imagen-dashboard-clients.png';

// Datos de ejemplo para clientes potenciales
const mockPotentialClients = [
    { id: 1, name: 'Ana García', source: 'Instagram', date: '2023-05-15' },
    { id: 2, name: 'Carlos Pérez', source: 'Facebook', date: '2023-05-16' },
    { id: 3, name: 'Laura Martínez', source: 'Referido', date: '2023-05-17' },
    // ... más clientes potenciales
];

const PotentialClients = () => {
    const [potentialClients, setPotentialClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    useEffect(() => {
        // Aquí normalmente harías una llamada a tu API
        setPotentialClients(mockPotentialClients);
    }, []);

    const handleAddClient = (id) => {
        // Lógica para añadir el cliente
        console.log(`Añadir cliente con ID: ${id}`);
    };

    const handleDeleteClient = (id) => {
        // Lógica para eliminar el cliente potencial
        setPotentialClients(potentialClients.filter(client => client.id !== id));
    };

    const handleOpenModal = (client) => {
        setSelectedClient(client);
    };

    const handleCloseModal = () => {
        setSelectedClient(null);
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <Box 
                sx={{ 
                    flexGrow: 1, 
                    p: 3, 
                    position: 'relative',
                    minHeight: 'calc(100vh - 64px)',
                    filter: 'blur(5px)', // Añadimos el efecto de desenfoque
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundImage: `url(${clientsBackground})`,
                        backgroundSize: '50%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        opacity: 0.1,
                        zIndex: -1,
                    }
                }}
            >
                <Typography variant="h4" gutterBottom>
                    Clientes Potenciales
                </Typography>
                <Typography variant="subtitle1" gutterBottom sx={{ mb: 4 }}>
                    Gestiona tus leads y conviértelos en clientes
                </Typography>

                <Paper elevation={2} sx={{ borderRadius: 4, overflow: 'hidden' }}>
                    <List>
                        {potentialClients.map((client) => (
                            <ListItem
                                key={client.id}
                                onClick={() => handleOpenModal(client)}
                                sx={{ cursor: 'pointer' }}
                                secondaryAction={
                                    <>
                                        <IconButton edge="end" aria-label="add" onClick={() => handleAddClient(client.id)}>
                                            <PersonAddIcon />
                                        </IconButton>
                                        <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteClient(client.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </>
                                }
                            >
                                <ListItemAvatar>
                                    <Avatar>{client.name[0]}</Avatar>
                                </ListItemAvatar>
                                <ListItemText 
                                    primary={client.name} 
                                    secondary={
                                        <>
                                            <Chip label={client.source} size="small" sx={{ mr: 1 }} />
                                            {client.date}
                                        </>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </Paper>

                <Modal
                    open={selectedClient !== null}
                    onClose={handleCloseModal}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Paper
                        sx={{
                            width: '80%',
                            maxWidth: 600,
                            p: 4,
                            outline: 'none',
                            borderRadius: '16px', // Bordes más curvos
                            boxShadow: '0 4px 20px rgba(0,0,0,0.1)', // Sombra suave
                        }}
                    >
                        {selectedClient && (
                            <>
                                <Typography variant="h4" gutterBottom>
                                    {selectedClient.name}
                                </Typography>
                                <Divider sx={{ my: 2 }} /> {/* Divisor añadido */}
                                <Typography variant="h6" gutterBottom>
                                    Ficha
                                </Typography>
                                <Typography paragraph>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </Typography>
                                <Typography variant="h6" gutterBottom>
                                    Datos de contacto
                                </Typography>
                                <Typography paragraph>
                                    Email: {selectedClient.name.toLowerCase().replace(' ', '.')}@example.com
                                    <br />
                                    Teléfono: +34 600 000 000
                                </Typography>
                                <Typography variant="h6" gutterBottom>
                                    Interés
                                </Typography>
                                <Typography paragraph>
                                    Servicios de consultoría empresarial
                                </Typography>
                                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<PersonAddIcon />}
                                        onClick={() => handleAddClient(selectedClient.id)}
                                    >
                                        Añadir Lead
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        startIcon={<DeleteIcon />}
                                        onClick={() => handleDeleteClient(selectedClient.id)}
                                    >
                                        Rechazar Lead
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Paper>
                </Modal>
            </Box>

            {/* Modal superpuesto */}
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                }}
            >
                <Paper
                    sx={{
                        width: '80%',
                        maxWidth: 600,
                        p: 4,
                        outline: 'none',
                        borderRadius: '16px',
                        boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="h4" gutterBottom>
                        ¡Estamos trabajando en esto!
                    </Typography>
                    <Typography variant="h5" color="primary" sx={{ mt: 2 }}>
                        Building the future!
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        Pronto podrás gestionar tus leads de manera eficiente.
                    </Typography>
                </Paper>
            </Box>
        </Box>
    );
};

export default PotentialClients;
