import React, { useState, useContext } from 'react';
import { 
    IconButton, 
    Popover, 
    TextField, 
    Button, 
    Box, 
    Typography,
    Snackbar,
    Alert
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { AuthContext } from '../../contexts/AuthContext'; // Asegúrate de que la ruta sea correcta

const AssistanceButton = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [message, setMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const { userDetails } = useContext(AuthContext);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSubmit = async () => {
        if (!message.trim()) {
            setSnackbar({ open: true, message: 'Por favor, escribe un mensaje', severity: 'warning' });
            return;
        }

        setIsSubmitting(true);
        const url = 'https://europe-southwest1-fitai-2cd85.cloudfunctions.net/solicitud-asistencia';
        const data = {
            name: userDetails?.display_name || 'Usuario no identificado',
            email: userDetails?.email || 'Email no disponible',
            message: message,
            date: new Date().toISOString()
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                setSnackbar({ open: true, message: 'Mensaje enviado con éxito', severity: 'success' });
                setMessage('');
                handleClose();
            } else {
                throw new Error('Error al enviar el mensaje');
            }
        } catch (error) {
            console.error('Error:', error);
            setSnackbar({ open: true, message: 'Error al enviar el mensaje. Por favor, intenta de nuevo.', severity: 'error' });
        } finally {
            setIsSubmitting(false);
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'assistance-popover' : undefined;

    return (
        <>
            <IconButton
                aria-label="assistance"
                onClick={handleClick}
                sx={{
                    position: 'fixed',
                    top: 16,
                    right: 16,
                    bgcolor: 'rgba(255, 87, 34, 0.1)',
                    '&:hover': {
                        bgcolor: 'rgba(255, 87, 34, 0.2)',
                    },
                    zIndex: 1300,
                }}
            >
                <HelpOutlineIcon sx={{ color: '#FF5722' }} />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box sx={{ p: 2, maxWidth: 300 }}>
                    <Typography variant="h6" gutterBottom>
                        Asistencia
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        ¿Necesitas ayuda? Envíanos tu consulta y te responderemos lo antes posible. Tu mensaje será enviado automáticamente a contacto@fitailabs.com.
                    </Typography>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        placeholder="Escribe tu mensaje aquí"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        fullWidth
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Enviando...' : 'Enviar'}
                    </Button>
                </Box>
            </Popover>
            <Snackbar 
                open={snackbar.open} 
                autoHideDuration={6000} 
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default AssistanceButton;
