// src/components/common/RestrictedAccess.js
import React, { useState } from 'react';
import { Box, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useAuth } from '../../contexts/AuthContext'; // Asegúrate de importar tu contexto de autenticación

const RestrictedAccess = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser, userDetails } = useAuth(); // Asegúrate de obtener userDetails

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const showDialog = (message) => {
    setDialogMessage(message);
    setOpenDialog(true);
  };

  const handleDashboardSubscription = async () => {
    setIsLoading(true);
    try {
      const functions = getFunctions();
      const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
      
      const result = await createCheckoutSession({
        priceId: 'price_1Q8nnB06MQnyY4JVsigIPsi2',
        returnUrl: window.location.origin, // Quita /subscription-confirmation de aquí
        customerEmail: currentUser.email,
        customerName: userDetails.name || currentUser.displayName || '',
      });

      if (result.data && result.data.url) {
        window.location.href = result.data.url;
      } else {
        showDialog('Error: No se recibió una URL de checkout válida.');
      }
    } catch (error) {
      console.error('Error al crear la sesión de checkout:', error);
      showDialog(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        backdropFilter: 'blur(5px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          padding: 4,
          borderRadius: 2,
          textAlign: 'center',
          maxWidth: 400,
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography variant="h5" gutterBottom>
          Acceso Restringido
        </Typography>
        <Typography variant="body1" paragraph>
          Necesitas desbloquear la versión de la plataforma para acceder a esta funcionalidad.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDashboardSubscription}
          disabled={isLoading}
        >
          {isLoading ? 'Cargando...' : 'Desbloquear Acceso'}
        </Button>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Aviso</DialogTitle>
        <DialogContent>
          <Typography>{dialogMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RestrictedAccess;
