import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF5722', // Naranja vivo
      light: '#FF8A65', // Una versión más clara del naranja
      dark: '#E64A19', // Una versión más oscura del naranja
      contrastText: '#fff', // Texto blanco para contrastar con el naranja
    },
    secondary: {
      main: '#2196F3',
      light: '#64B5F6',
      dark: '#1976D2',
      contrastText: '#fff',
    },
    // Puedes añadir más colores personalizados aquí si lo deseas
  },
  typography: {
    fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    h1: {
      fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    },
    h2: {
      fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    },
    h3: {
      fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    },
    h4: {
      fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    },
    h5: {
      fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    },
    h6: {
      fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    },
    subtitle1: {
      fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    },
    subtitle2: {
      fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    },
    body1: {
      fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    },
    body2: {
      fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    },
    button: {
      fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    },
    caption: {
      fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    },
    overline: {
      fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
        },
      },
    },
  },
  // Aquí puedes añadir más personalizaciones al tema si lo deseas
});

export default theme;
