import React, { useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { Box, Typography, Paper, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

const Logout = () => {
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        logout();
    }, [logout]);

    const handleLogin = () => {
        navigate('/login');
    };

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            minHeight: '100vh',
            alignItems: 'center', 
            justifyContent: 'center',
            backgroundColor: '#f5f5f5'
        }}>
            <Paper elevation={3} sx={{ padding: 4, width: '100%', maxWidth: 400, textAlign: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
                    <img src={logo} alt="FitAI Labs Logo" style={{ maxWidth: '200px', height: 'auto' }} />
                </Box>
                <Typography variant="h5" component="h1" gutterBottom>
                    Has cerrado sesión
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Gracias por usar nuestra aplicación. ¡Esperamos verte pronto!
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleLogin}
                    sx={{ mt: 3 }}
                >
                    Volver a iniciar sesión
                </Button>
            </Paper>
        </Box>
    );
};

export default Logout;
