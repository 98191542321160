import React, { useState, useEffect, useContext } from 'react';
import { Typography, Box, List, ListItem, ListItemText, Paper, IconButton, Container } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import backgroundImage from '../../assets/images/notifications-sf.png';
import { AuthContext } from '../../contexts/AuthContext';
import { doc, getDoc, updateDoc, arrayRemove } from 'firebase/firestore';
import { db } from '../../config/firebaseConfig';

const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const { currentUser } = useContext(AuthContext);

    useEffect(() => {
        const fetchNotifications = async () => {
            if (currentUser) {
                const userDocRef = doc(db, 'userDetails', currentUser.uid);
                const userDocSnap = await getDoc(userDocRef);
                
                if (userDocSnap.exists()) {
                    const userData = userDocSnap.data();
                    let newNotifications = [];

                    // Manejar solicitudes
                    if (userData.solicitudes && userData.solicitudes.length > 0) {
                        newNotifications = [
                            ...newNotifications,
                            ...userData.solicitudes.map(solicitud => ({
                                id: solicitud.uid,
                                title: 'Cliente',
                                description: 'Un nuevo cliente busca vincularse contigo.',
                                unread: true,
                                type: 'solicitud'
                            }))
                        ];
                    }

                    // Manejar notificaciones de clientes añadidos
                    if (userData.notificaciones) {
                        newNotifications = [
                            ...newNotifications,
                            ...userData.notificaciones
                        ];
                    }

                    setNotifications(newNotifications);
                    
                    // Marcar todas las notificaciones como leídas
                    const updatedNotifications = newNotifications.map(n => ({...n, unread: false}));
                    setNotifications(updatedNotifications);
                    
                    // Actualizar la base de datos
                    await updateDoc(userDocRef, {
                        solicitudes: userData.solicitudes ? userData.solicitudes.map(s => ({...s, unread: false})) : [],
                        notificaciones: userData.notificaciones ? userData.notificaciones.map(n => ({...n, unread: false})) : []
                    });
                }
            }
        };

        fetchNotifications();
    }, [currentUser]);

    const handleRemoveNotification = async (id, type) => {
        setNotifications(notifications.filter(notification => notification.id !== id));
        
        if (currentUser) {
            const userDocRef = doc(db, 'userDetails', currentUser.uid);
            if (type === 'solicitud') {
                await updateDoc(userDocRef, {
                    solicitudes: arrayRemove({uid: id})
                });
            } else {
                await updateDoc(userDocRef, {
                    notificaciones: arrayRemove({id: id})
                });
            }
        }
    };

    return (
        <Box sx={{ 
            position: 'relative',
            flexGrow: 1, 
            p: 3,
            minHeight: 'calc(100vh - 64px)',
            '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: '50%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                opacity: 0.1,
                zIndex: -1,
            }
        }}>
            <Typography variant="h4" gutterBottom align="left" sx={{ mb: 3 }}>
                Notificaciones
            </Typography>
            <Container maxWidth="md">
                <List>
                    {notifications.map((notification) => (
                        <ListItem
                            key={notification.id}
                            sx={{
                                mb: 2,
                                borderRadius: '20px',
                                border: notification.unread ? '2px solid #FF5722' : '1px solid #e0e0e0',
                                backgroundColor: notification.unread ? 'rgba(255, 87, 34, 0.05)' : 'white',
                                position: 'relative',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                overflow: 'visible',
                                paddingLeft: '16px',
                                '&:hover': {
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
                                },
                            }}
                        >
                            {notification.unread && (
                                <FiberManualRecordIcon 
                                    sx={{ 
                                        color: '#FF5722', 
                                        position: 'absolute', 
                                        left: '-25px',
                                        top: '50%',
                                        transform: 'translateY(-50%)'
                                    }} 
                                />
                            )}
                            {notification.type === 'clientAdded' && (
                                <PersonAddIcon 
                                    sx={{ 
                                        color: '#4CAF50', 
                                        marginRight: '8px'
                                    }} 
                                />
                            )}
                            <ListItemText
                                primary={notification.title}
                                secondary={notification.description}
                                sx={{ pr: 6 }}
                            />
                            <IconButton 
                                edge="end" 
                                aria-label="delete"
                                onClick={() => handleRemoveNotification(notification.id, notification.type)}
                                sx={{ 
                                    position: 'absolute', 
                                    right: 16, 
                                    top: '50%', 
                                    transform: 'translateY(-50%)',
                                    bgcolor: 'rgba(0, 0, 0, 0.05)',
                                    width: 36,
                                    height: 36,
                                    '&:hover': {
                                        bgcolor: 'rgba(0, 0, 0, 0.1)',
                                    }
                                }}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </ListItem>
                    ))}
                </List>
                {notifications.length === 0 && (
                    <Paper elevation={3} sx={{ 
                        p: 4, 
                        textAlign: 'center',
                        borderRadius: '20px',
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    }}>
                        <Typography variant="h6" gutterBottom>
                            No tienes notificaciones en este momento
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            Las notificaciones aparecerán aquí cuando haya nuevas actualizaciones.
                        </Typography>
                    </Paper>
                )}
            </Container>
        </Box>
    );
};

export default Notifications;