const generarIdUnico = (longitud = 5) => {
  const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let id = '';

  for (let i = 0; i < longitud; i++) {
    id += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
  }

  return id;
};

export const generateIDPT = () => generarIdUnico();
export const generateIDBOUnico = () => generarIdUnico();
