import { useState, useContext } from 'react';
import { StripeContext } from '../contexts/StripeContext';
import { AuthContext } from '../contexts/AuthContext';

export const useStripeConnect = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { updateStripeAccount } = useContext(StripeContext);
    const { userDetails } = useContext(AuthContext);

    const createConnectedAccount = async () => {
        setLoading(true);
        setError(null);

        try {
            console.log('Iniciando creación de cuenta Stripe...');
            // 1. Crear la cuenta
            const accountResponse = await fetch('https://europe-southwest1-fitai-2cd85.cloudfunctions.net/create-connected-account', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: userDetails?.uid,
                    email: userDetails?.email,
                    name: userDetails?.displayName || userDetails?.email
                })
            });

            const accountData = await accountResponse.json();
            console.log('Respuesta de creación de cuenta:', accountData);

            if (!accountResponse.ok) throw new Error(accountData.error);

            if (accountData.account && updateStripeAccount) {
                console.log('Actualizando cuenta en el contexto:', accountData.account);
                await updateStripeAccount(accountData.account);
            }

            // 2. Obtener el link de onboarding
            console.log('Solicitando link de onboarding...');
            const linkResponse = await fetch('https://europe-southwest1-fitai-2cd85.cloudfunctions.net/create_account_link', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: userDetails?.uid
                })
            });

            const linkData = await linkResponse.json();
            console.log('Respuesta del link:', linkData);

            if (!linkResponse.ok) throw new Error(linkData.error);
            
            // Redirigir al usuario a la URL de Stripe
            if (linkData.url) {
                console.log('Redirigiendo a:', linkData.url);
                window.location.href = linkData.url;
            } else {
                console.error('No se recibió URL de redirección');
                throw new Error('No se recibió URL de redirección');
            }

            return linkData.url;
        } catch (err) {
            console.error('Error en createConnectedAccount:', err);
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return { createConnectedAccount, loading, error };
};
