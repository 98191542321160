import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Button,
    Box,
    Avatar,
    Paper,
    Grid,
} from '@mui/material';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import GoogleMeetIcon from '../../assets/images/google-meet-icon.png';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';

const IndividualClassSummaryModal = ({ open, onClose, classData }) => {
    if (!classData) return null;

    const buttonStyle = {
        borderRadius: '25px',
        padding: '10px 20px',
        textTransform: 'none',
        fontSize: '16px',
        color: 'white',
        backgroundColor: '#FF5722',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.3s ease',
        '&:hover': {
            backgroundColor: '#F4511E',
            boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
            transform: 'translateY(-2px)',
        }
    };

    const infoItemStyle = {
        display: 'flex',
        alignItems: 'center',
        mb: 1,
    };

    const iconStyle = {
        color: '#FF5722',
        mr: 1,
    };

    const isOnlineClass = classData.modalidad?.toLowerCase() === 'online';

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                style: {
                    borderRadius: '20px',
                    overflow: 'hidden',
                },
            }}
        >
            <Paper elevation={0} sx={{ 
                p: 3,
                background: 'linear-gradient(45deg, #FF5722, #FFA000)',
                color: 'white'
            }}>
                <DialogTitle sx={{ p: 0, fontSize: '24px', fontWeight: 'bold' }}>
                    Resumen de Clase Individual
                </DialogTitle>
            </Paper>
            <DialogContent sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper elevation={2} sx={{ p: 2, borderRadius: 2 }}>
                                <Typography variant="h6" sx={{ color: '#FF5722', fontWeight: 'bold', mb: 2 }}>
                                    {classData.nombreClase}
                                </Typography>
                                <Box sx={infoItemStyle}>
                                    <CalendarTodayIcon sx={iconStyle} />
                                    <Typography variant="body1">
                                        <strong>Fecha:</strong> {format(classData.Fecha, 'dd MMMM yyyy', { locale: es })}
                                    </Typography>
                                </Box>
                                <Box sx={infoItemStyle}>
                                    <AccessTimeIcon sx={iconStyle} />
                                    <Typography variant="body1">
                                        <strong>Hora:</strong> {format(classData.Hora, 'HH:mm')}
                                    </Typography>
                                </Box>
                                <Box sx={infoItemStyle}>
                                    <FitnessCenterIcon sx={iconStyle} />
                                    <Typography variant="body1">
                                        <strong>Duración:</strong> {classData.Duracion} minutos
                                    </Typography>
                                </Box>
                                <Box sx={infoItemStyle}>
                                    <PersonIcon sx={iconStyle} />
                                    <Typography variant="body1">
                                        <strong>Cliente:</strong> {classData.clientes[0]?.nombre || 'N/A'}
                                    </Typography>
                                </Box>
                                <Box sx={infoItemStyle}>
                                    <DescriptionIcon sx={iconStyle} />
                                    <Typography variant="body1">
                                        <strong>Modalidad:</strong> {classData.modalidad || 'N/A'}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                    
                    {classData.notas && (
                        <Paper elevation={2} sx={{ p: 2, mt: 3, borderRadius: 2 }}>
                            <Typography variant="h6" gutterBottom sx={{ color: '#FF5722' }}>
                                Notas
                            </Typography>
                            <Typography variant="body1">
                                {classData.notas}
                            </Typography>
                        </Paper>
                    )}
                </Box>
                
                {isOnlineClass && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                        <Button
                            variant="contained"
                            startIcon={<Avatar src={GoogleMeetIcon} sx={{ width: 24, height: 24 }} />}
                            sx={buttonStyle}
                            onClick={() => window.open('https://meet.google.com/', '_blank')}
                        >
                            Unirse a Google Meet
                        </Button>
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default IndividualClassSummaryModal;
