import React, { useState, useContext } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { 
    Box, Typography, TextField, Button, 
    FormControl, InputLabel, Select, MenuItem, 
    Paper, Link, CircularProgress
} from '@mui/material';
import logo from '../../assets/images/logo.png';
import backgroundImage from '../../assets/images/start-sf.png';
import { db, functions } from '../../config/firebaseConfig'; // Importa functions aquí
import { doc, setDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [name, setName] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [tipoProfesional, setTipoProfesional] = useState('');
    const [nombreBusiness, setNombreBusiness] = useState('');
    const [idBO, setIdBO] = useState('');
    const [idBOValido, setIdBOValido] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(''); // Añadimos el estado para el número de teléfono
    const { register, verificarIdBO, generarIdBOUnico, generarIDPT, login } = useContext(AuthContext);
    const navigate = useNavigate();

    const inputStyle = {
        '& .MuiOutlinedInput-root': {
            borderRadius: '15px',
        },
    };

    const buttonStyle = {
        borderRadius: '25px',
        textTransform: 'none',
        fontSize: '16px',
        padding: '10px 0',
        boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
        transition: 'all 0.15s ease',
        '&:hover': {
            transform: 'translateY(-1px)',
            boxShadow: '0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)',
        },
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        console.log('Iniciando proceso de registro');
        if (password !== confirmPassword) {
            alert('Las contraseñas no coinciden');
            return;
        }
        setIsLoading(true);
        try {
            console.log('Generando IDPT');
            let IDPT = await generarIDPT();
            console.log('IDPT generado:', IDPT);

            let IDBO = '';
            let nombreBusinessFinal = '';
            if (tipoProfesional === 'propietario_centro') {
                console.log('Generando IDBO para propietario de centro');
                IDBO = await generarIdBOUnico();
                nombreBusinessFinal = nombreBusiness;
                console.log('IDBO generado:', IDBO);
            } else if (tipoProfesional === 'entrenador_centro') {
                if (!idBOValido) {
                    alert('El IDBO ingresado no es válido');
                    setIsLoading(false);
                    return;
                }
                IDBO = idBO;
            }

            console.log('Preparando llamada a la Cloud Function registerUser');
            const registerUser = httpsCallable(functions, 'registerUser');
            
            console.log('Llamando a la Cloud Function registerUser');
            const result = await registerUser({
                email,
                password,
                displayName,
                name,
                apellidos,
                tipoProfesional,
                nombreBusiness: nombreBusinessFinal,
                idBO: IDBO,
                idpt: IDPT,
                phoneNumber
            });

            console.log('Resultado de la Cloud Function:', result);

            if (result.data && result.data.success) {
                console.log('Registro exitoso');
                // En lugar de navegar directamente, iniciamos sesión
                await login(email, password);
                // La redirección a /home la manejará el useEffect en App.js
            } else {
                throw new Error('Error en el registro: ' + (result.data ? result.data.error : 'Respuesta inesperada'));
            }
        } catch (error) {
            console.error('Error al registrarse:', error);
            alert(`Error al registrarse: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    const createCentroDocument = async (nombreCentro, idBO, uid, idpt) => {
        try {
            const centroRef = doc(db, 'Centros', idBO);  // Usamos idBO como ID del documento
            await setDoc(centroRef, {
                IDBO: idBO,
                propietarios: [idpt],
                trabajadores: [],
                clientes: [],
                nombreCentro: nombreCentro,
                descripcion: '',
                photo_url: ''
            });
            console.log('Documento del centro creado con éxito');
        } catch (error) {
            console.error('Error al crear el documento del centro:', error);
            throw error;
        }
    };

    const handleVerificarIdBO = async (idBO) => {
        const esValido = await verificarIdBO(idBO);
        setIdBOValido(esValido);
    };

    return (
        <Box sx={{ 
            position: 'relative',
            display: 'flex', 
            flexDirection: 'column', 
            minHeight: '100vh',
            alignItems: 'center', 
            justifyContent: 'center',
        }}>
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: '50%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                opacity: 0.2,
                zIndex: -1,
            }} />
            <Paper elevation={3} sx={{ 
                padding: 4, 
                width: '100%', 
                maxWidth: 400,
                backgroundColor: 'white',
                position: 'relative',
                zIndex: 1,
                borderRadius: '20px',
            }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
                    <img src={logo} alt="FitAI Labs Logo" style={{ maxWidth: '200px', height: 'auto' }} />
                </Box>
                <Typography variant="h5" component="h1" gutterBottom textAlign="center">
                    Registro
                </Typography>
                <Box component="form" onSubmit={handleRegister} noValidate>
                    <TextField
                        fullWidth margin="normal" required
                        id="email" label="Correo electrónico" name="email"
                        autoComplete="email" value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={inputStyle}
                    />
                    <TextField
                        fullWidth margin="normal" required
                        name="password" label="Contraseña" type="password"
                        id="password" value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        sx={inputStyle}
                    />
                    <TextField
                        fullWidth margin="normal" required
                        name="confirmPassword" label="Confirmar contraseña" type="password"
                        id="confirmPassword" value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        sx={inputStyle}
                    />
                    <TextField
                        fullWidth margin="normal" required
                        id="displayName" label="Nombre de perfil" name="displayName"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                        sx={inputStyle}
                    />
                    <TextField
                        fullWidth margin="normal" required
                        id="name" label="Nombre real" name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        sx={inputStyle}
                    />
                    <TextField
                        fullWidth margin="normal" required
                        id="apellidos" label="Apellidos" name="apellidos"
                        value={apellidos}
                        onChange={(e) => setApellidos(e.target.value)}
                        sx={inputStyle}
                    />
                    <FormControl fullWidth margin="normal" sx={inputStyle}>
                        <InputLabel id="tipo-profesional-label">Tipo de profesional</InputLabel>
                        <Select
                            labelId="tipo-profesional-label"
                            id="tipoProfesional"
                            value={tipoProfesional}
                            label="Tipo de profesional"
                            onChange={(e) => setTipoProfesional(e.target.value)}
                        >
                            <MenuItem value="entrenador_independiente">Entrenador Independiente</MenuItem>
                            <MenuItem value="propietario_centro">Propietario de Centro</MenuItem>
                            <MenuItem value="entrenador_centro">Entrenador de Centro</MenuItem>
                        </Select>
                    </FormControl>
                    {tipoProfesional === 'propietario_centro' && (
                        <TextField
                            fullWidth margin="normal" required
                            id="nombreBusiness" label="Nombre del centro" name="nombreBusiness"
                            value={nombreBusiness}
                            onChange={(e) => setNombreBusiness(e.target.value)}
                            sx={inputStyle}
                        />
                    )}
                    {tipoProfesional === 'entrenador_centro' && (
                        <TextField
                            fullWidth margin="normal" required
                            id="idBO" label="Código del centro (IDBO)" name="idBO"
                            value={idBO}
                            onChange={(e) => {
                                setIdBO(e.target.value);
                                handleVerificarIdBO(e.target.value);
                            }}
                            sx={inputStyle}
                            helperText={idBOValido ? "IDBO válido" : "IDBO no encontrado"}
                            error={!idBOValido && idBO !== ''}
                        />
                    )}
                    <TextField
                        fullWidth margin="normal" required
                        id="phoneNumber" label="Número de teléfono" name="phoneNumber"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        sx={inputStyle}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={isLoading}
                        sx={{ ...buttonStyle, mt: 3, mb: 2 }}
                    >
                        {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Registrarse'}
                    </Button>
                </Box>
                <Typography variant="body2" align="center">
                    ¿Ya tienes una cuenta?{' '}
                    <Link component={RouterLink} to="/login">
                        Inicia sesión
                    </Link>
                </Typography>
            </Paper>
        </Box>
    );
};

export default Register;
