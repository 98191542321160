import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material';
import { db } from '../../config/firebaseConfig';

const StripeConnectReturn = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    const processReturn = async () => {
      try {
        const stripeRef = db.collection('userDetails')
          .doc(userId)
          .collection('stripe_connect')
          .doc('account');

        await stripeRef.update({
          onboardingStatus: 'completed',
          lastUpdated: new Date()
        });

        // Redirigir después de una breve pausa
        setTimeout(() => {
          navigate('/facturacion', { 
            state: { stripeSetupComplete: true }
          });
        }, 2000);

      } catch (error) {
        console.error('Error procesando retorno:', error);
        setStatus('error');
      }
    };

    processReturn();
  }, [userId, navigate]);

  return (
    <Box sx={{
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      bgcolor: 'background.default',
      p: 3
    }}>
      {status === 'loading' ? (
        <>
          <CircularProgress sx={{ mb: 3 }} />
          <Typography variant="h5" gutterBottom>
            Completando la configuración...
          </Typography>
        </>
      ) : (
        <Typography color="error">
          Ha ocurrido un error. Por favor, contacta con soporte.
        </Typography>
      )}
    </Box>
  );
};

export default StripeConnectReturn;
