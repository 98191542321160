import React, { useState, useEffect, useContext } from 'react';
import { 
    Typography, 
    Box, 
    Button, 
    Paper,
    List,
    ListItem,
    ListItemText,
    Divider
} from '@mui/material';
import clientsBackground from '../../assets/images/imagen-dashboard-clients.png';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { AuthContext } from '../../contexts/AuthContext';
import { db } from '../../config/firebaseConfig';
import { doc, getDoc, updateDoc, arrayRemove, arrayUnion } from 'firebase/firestore';

const AddClient = () => {
    const [pendingRequests, setPendingRequests] = useState([]);
    const { currentUser, userDetails } = useContext(AuthContext);

    useEffect(() => {
        fetchPendingRequests();
    }, [currentUser, userDetails]);

    const fetchPendingRequests = async () => {
        if (currentUser && userDetails) {
            let requests = [];
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                const userDocRef = doc(db, 'userDetails', currentUser.uid);
                const userDocSnap = await getDoc(userDocRef);
                
                if (userDocSnap.exists()) {
                    const userData = userDocSnap.data();
                    requests = userData.solicitudes || [];
                }
            } else if (['propietario_centro', 'entrenador_centro'].includes(userDetails.tipo_profesional)) {
                const centroDocRef = doc(db, 'Centros', userDetails.IDBO);
                const centroDocSnap = await getDoc(centroDocRef);
                
                if (centroDocSnap.exists()) {
                    const centroData = centroDocSnap.data();
                    requests = centroData.solicitudes || [];
                }
            }
            setPendingRequests(requests);
        }
    };

    const handleRejectRequest = async (requestUid) => {
        try {
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                const trainerDocRef = doc(db, 'userDetails', currentUser.uid);
                await updateDoc(trainerDocRef, {
                    solicitudes: arrayRemove(pendingRequests.find(request => request.uid === requestUid))
                });
            } else {
                const centroDocRef = doc(db, 'Centros', userDetails.IDBO);
                await updateDoc(centroDocRef, {
                    solicitudes: arrayRemove(pendingRequests.find(request => request.uid === requestUid))
                });
            }

            const userDocRef = doc(db, 'userDetails', requestUid);
            await updateDoc(userDocRef, {
                tiene_entrenador: false,
                en_solicitud: false,
                solicitud_aceptada: false,
                IDPT: null,  // Establecer IDPT a null
                IDBO: null   // Establecer IDBO a null
            });

            setPendingRequests(prevRequests => prevRequests.filter(request => request.uid !== requestUid));
            console.log(`Solicitud rechazada: ${requestUid}`);
        } catch (error) {
            console.error("Error al rechazar la solicitud:", error);
        }
    };

    const handleAcceptRequest = async (requestUid) => {
        try {
            const requestToAccept = pendingRequests.find(request => request.uid === requestUid);

            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                const trainerDocRef = doc(db, 'userDetails', currentUser.uid);
                await updateDoc(trainerDocRef, {
                    solicitudes: arrayRemove(requestToAccept),
                    clientes: arrayUnion(requestToAccept)
                });
            } else {
                const centroDocRef = doc(db, 'Centros', userDetails.IDBO);
                await updateDoc(centroDocRef, {
                    solicitudes: arrayRemove(requestToAccept),
                    clientes: arrayUnion(requestToAccept)
                });
            }

            const clientDocRef = doc(db, 'userDetails', requestUid);
            await updateDoc(clientDocRef, {
                tiene_entrenador: true,
                en_solicitud: false,
                solicitud_aceptada: true
            });

            setPendingRequests(prevRequests => prevRequests.filter(request => request.uid !== requestUid));
            console.log(`Solicitud aceptada: ${requestUid}`);
        } catch (error) {
            console.error("Error al aceptar la solicitud:", error);
        }
    };

    return (
        <Box 
            sx={{ 
                flexGrow: 1, 
                p: 3,
                position: 'relative',
                minHeight: 'calc(100vh - 64px)',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: `url(${clientsBackground})`,
                    backgroundSize: '50%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    opacity: 0.1,
                    zIndex: -1,
                }
            }}
        >
            <Typography variant="h4" gutterBottom>
                Añade a un nuevo cliente
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Gestiona nuevos ingresos de clientes desde esta página
            </Typography>

            <Paper elevation={2} sx={{ 
                p: 4, 
                mt: 3, 
                borderRadius: 4,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                    boxShadow: 3,
                }
            }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main', mb: 3, display: 'flex', alignItems: 'center' }}>
                    <PersonAddIcon sx={{ mr: 1 }} />
                    Solicitudes de Vinculación
                </Typography>
                {pendingRequests.length > 0 ? (
                    <List>
                        {pendingRequests.map((request, index) => (
                            <React.Fragment key={request.uid}>
                                <ListItem
                                    sx={{
                                        mb: 2,
                                        bgcolor: 'background.paper',
                                        borderRadius: 2,
                                        boxShadow: 1,
                                    }}
                                >
                                    <ListItemText
                                        primary={request.nombre}
                                        secondary={
                                            <>
                                                <Typography component="span" variant="body2" color="text.primary">
                                                    {request.email}
                                                </Typography>
                                            </>
                                        }
                                    />
                                    <Button
                                        startIcon={<CheckCircleIcon />}
                                        variant="contained"
                                        color="success"
                                        onClick={() => handleAcceptRequest(request.uid)}
                                        sx={{ mr: 1 }}
                                    >
                                        Aceptar
                                    </Button>
                                    <Button
                                        startIcon={<CancelIcon />}
                                        variant="contained"
                                        color="error"
                                        onClick={() => handleRejectRequest(request.uid)}
                                    >
                                        Rechazar
                                    </Button>
                                </ListItem>
                                {index < pendingRequests.length - 1 && <Divider />}
                            </React.Fragment>
                        ))}
                    </List>
                ) : (
                    <Typography variant="body1" sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                        No hay solicitudes pendientes en este momento.
                    </Typography>
                )}
            </Paper>
        </Box>
    );
};

export default AddClient;
