import React, { useState, useContext, useEffect } from 'react';
import { Typography, Box, Paper, Grid, Button, TextField, IconButton, Modal, Fade, List, ListItem, ListItemIcon, ListItemText, Avatar, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PeopleIcon from '@mui/icons-material/People';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SendIcon from '@mui/icons-material/Send';
import BoltIcon from '@mui/icons-material/Bolt';
import { Link } from 'react-router-dom';
import homeBackground from '../../assets/images/imagen-home-dashboard.png';
import axios from 'axios';
import { AuthContext } from '../../contexts/AuthContext';
import { 
    getFirestore, 
    doc, 
    getDoc, 
    updateDoc, 
    collection, 
    query, 
    where, 
    orderBy, 
    limit, 
    getDocs,
    setDoc 
} from "firebase/firestore";
import { db } from '../../config/firebaseConfig';
import ReactMarkdown from 'react-markdown';
import RefreshIcon from '@mui/icons-material/Refresh';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import Lottie from 'lottie-react';
import loadingAnimation from '../../assets/animations/Resumen_Home_Loading.json';
import confetti from 'canvas-confetti';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import InfoIcon from '@mui/icons-material/Info';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import NorthRoundedIcon from '@mui/icons-material/NorthRounded';
import { getAuth } from "firebase/auth";

const InfoCard = ({ title, value, icon, change }) => (
    <Paper elevation={2} sx={{ 
        p: 3, 
        borderRadius: 4,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: 3,
        }
    }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6" component="div" color="text.secondary">
                {title}
            </Typography>
            {icon}
        </Box>
        <Typography variant="h4" component="div" sx={{ mb: 1 }}>
            {value}
        </Typography>
        {change && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {change > 0 ? (
                    <ArrowUpwardIcon sx={{ color: 'success.main', mr: 1 }} />
                ) : (
                    <ArrowDownwardIcon sx={{ color: 'error.main', mr: 1 }} />
                )}
                <Typography 
                    variant="body2" 
                    color={change > 0 ? 'success.main' : 'error.main'}
                >
                    {Math.abs(change)}% este mes
                </Typography>
            </Box>
        )}
    </Paper>
);

const LoadingAnimation = () => (
    <Box 
        sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            p: 3,
            position: 'relative'
        }}
    >
        <Lottie 
            animationData={loadingAnimation}
            style={{ 
                width: 150,
                height: 150,
                filter: 'drop-shadow(0 0 10px rgba(255, 87, 34, 0.3))'
            }}
        />
        <Typography 
            variant="h6" 
            sx={{ 
                mt: 2,
                color: '#FF5722',
                textAlign: 'center',
                animation: 'pulse 1.5s infinite',
                '@keyframes pulse': {
                    '0%': { opacity: 0.6 },
                    '50%': { opacity: 1 },
                    '100%': { opacity: 0.6 }
                }
            }}
        >
            Generando tu resumen diario...
        </Typography>
    </Box>
);

const Home = () => {
    const { userDetails } = useContext(AuthContext);
    const [chatInput, setChatInput] = useState('');
    const [chatResponse, setChatResponse] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [clientCount, setClientCount] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [assistantId, setAssistantId] = useState('');
    const [dailySummaryData, setDailySummaryData] = useState(null);
    const [isLoadingSummary, setIsLoadingSummary] = useState(false);
    const [summaryError, setSummaryError] = useState(null);
    const [completedItems, setCompletedItems] = useState(new Set());
    const [clientesStats, setClientesStats] = useState({
        historico: [],
        mesActual: {
            total: 0,
            variacion: 0
        },
        mesesFaltantes: 0
    });

    useEffect(() => {
        fetchDailySummary();
    }, [userDetails]);

    useEffect(() => {
        const fetchClientCount = async () => {
            if (!userDetails) return;

            try {
                let count = 0;
                if (userDetails.tipo_profesional === 'entrenador_independiente') {
                    const userRef = doc(db, 'userDetails', userDetails.uid);
                    const userSnap = await getDoc(userRef);
                    if (userSnap.exists()) {
                        const userData = userSnap.data();
                        count = userData.clientes?.length || 0;
                    }
                } else if (['propietario_centro', 'entrenador_centro'].includes(userDetails.tipo_profesional)) {
                    if (userDetails.IDBO) {
                        const centroRef = doc(db, 'Centros', userDetails.IDBO);
                        const centroSnap = await getDoc(centroRef);
                        if (centroSnap.exists()) {
                            const centroData = centroSnap.data();
                            count = centroData.clientes?.length || 0;
                        }
                    }
                }
                setClientCount(count);
            } catch (error) {
                console.error("Error al obtener el número de clientes:", error);
            }
        };

        fetchClientCount();

        const fetchAssistantId = async () => {
            const docRef = doc(db, 'parametros_globales', 'dashboard_assistant');
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setAssistantId(docSnap.data().assistant_id);
            }
        };

        fetchAssistantId();
    }, [userDetails]);

    useEffect(() => {
        fetchHistoricoClientes();
    }, [userDetails]);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleChatSubmit = async (e) => {
        e.preventDefault();
        if (!chatInput.trim()) return;

        setIsLoading(true);
        try {
            const auth = getAuth();
            const idToken = await auth.currentUser.getIdToken();

            let requestData = {
                question: chatInput,
                assistant_id: assistantId,
                thread_id: userDetails.thread_id || '',
                nombre: userDetails.name,
                tipo_de_profesional: userDetails.tipo_profesional
            };

            if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                if (userDetails.IDBO) {
                    const centroRef = doc(db, 'Centros', userDetails.IDBO);
                    const centroSnap = await getDoc(centroRef);
                    if (centroSnap.exists()) {
                        requestData.nombre_business = centroSnap.data().nombreCentro;
                    }
                }
            }

            const response = await fetch(
                'https://europe-southwest1-fitai-2cd85.cloudfunctions.net/asistente-dashboard',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${idToken}`
                    },
                    body: JSON.stringify(requestData)
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Error en la respuesta del servidor');
            }

            const data = await response.json();
            setChatResponse(data.respuesta);
            setIsModalOpen(true);

            if (!userDetails.thread_id && data.thread_id) {
                const userRef = doc(db, 'userDetails', userDetails.uid);
                await updateDoc(userRef, {
                    thread_id: data.thread_id
                });
            }
        } catch (error) {
            console.error("Error al obtener la respuesta:", error);
            setChatResponse("Lo siento, ha ocurrido un error al procesar tu pregunta.");
        } finally {
            setIsLoading(false);
            setChatInput('');
        }
    };

    const fetchDailySummary = async () => {
        if (!userDetails?.uid) return;

        setIsLoadingSummary(true);
        setSummaryError(null);
        
        try {
            const currentDate = getCurrentDate();
            const summaryRef = doc(db, 'userDetails', userDetails.uid, 'daily_summaries', currentDate);
            const summarySnap = await getDoc(summaryRef);
            
            if (summarySnap.exists()) {
                setDailySummaryData(summarySnap.data());
            } else {
                setDailySummaryData(null);
            }
        } catch (error) {
            console.error('Error al cargar el resumen diario:', error);
            setSummaryError('Error al cargar el resumen diario');
        } finally {
            setIsLoadingSummary(false);
        }
    };

    const generateDailySummary = async () => {
        if (!userDetails?.uid) return;

        setIsLoadingSummary(true);
        setSummaryError(null);

        try {
            const requestData = {
                uid: userDetails.uid,
                IDPT: userDetails.IDPT || null,
                IDBO: userDetails.IDBO || null,
                tipo_profesional: userDetails.tipo_profesional
            };

            const response = await axios.post(
                'https://europe-southwest1-fitai-2cd85.cloudfunctions.net/crear-resumen-profesional',
                requestData
            );

            setTimeout(async () => {
                await fetchDailySummary();
            }, 1000);

        } catch (error) {
            console.error('Error al generar el resumen:', error);
            setSummaryError('No se pudo generar el resumen. Por favor, inténtalo de nuevo.');
        } finally {
            setIsLoadingSummary(false);
        }
    };

    const getCurrentDate = () => {
        return new Date().toISOString().split('T')[0];
    };

    const handleItemClick = (index, event) => {
        const newCompletedItems = new Set(completedItems);
        if (completedItems.has(index)) {
            newCompletedItems.delete(index);
        } else {
            newCompletedItems.add(index);
            confetti({
                particleCount: 50,
                spread: 60,
                origin: {
                    x: event.clientX / window.innerWidth,
                    y: event.clientY / window.innerHeight
                },
                colors: ['#FF5722', '#FF9800', '#FFC107']
            });
        }
        setCompletedItems(newCompletedItems);
    };

    const renderSummaryContent = () => {
        if (!dailySummaryData) return null;

        const resumenData = dailySummaryData.resumen;

        return (
            <Box sx={{ p: 2 }}>
                <Typography 
                    variant="h6" 
                    sx={{ 
                        mb: 3, 
                        color: 'text.primary',
                        textAlign: 'center'
                    }}
                >
                    {resumenData.title}
                </Typography>

                <List>
                    {resumenData.summary_items.map((item, index) => (
                        <ListItem 
                            key={index}
                            sx={{
                                py: 1.5,
                                backgroundColor: index % 2 === 0 ? 'rgba(255, 87, 34, 0.05)' : 'transparent',
                                borderRadius: 1,
                                mb: 1,
                                cursor: 'pointer',
                                transition: 'all 0.2s ease-in-out',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 87, 34, 0.1)',
                                }
                            }}
                            onClick={(e) => handleItemClick(index, e)}
                        >
                            <ListItemIcon>
                                <Typography 
                                    variant="h6" 
                                    sx={{ 
                                        fontSize: '1.5rem'
                                    }}
                                >
                                    {item.emoji}
                                </Typography>
                            </ListItemIcon>
                            <ListItemText 
                                primary={item.text}
                                sx={{
                                    '& .MuiListItemText-primary': {
                                        fontWeight: 500,
                                        color: 'text.primary',
                                        textDecoration: completedItems.has(index) ? 'line-through' : 'none',
                                        opacity: completedItems.has(index) ? 0.6 : 1,
                                        transition: 'all 0.2s ease-in-out'
                                    }
                                }}
                            />
                        </ListItem>
                    ))}
                </List>

                <Box 
                    sx={{ 
                        mt: 3,
                        p: 2,
                        backgroundColor: 'rgba(255, 87, 34, 0.1)',
                        borderRadius: 2,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2
                    }}
                >
                    <EmojiEmotionsIcon sx={{ color: '#FF5722' }} />
                    <Typography 
                        variant="body1"
                        sx={{ 
                            fontStyle: 'italic',
                            color: 'text.secondary',
                            fontWeight: 500
                        }}
                    >
                        {resumenData.conclusion}
                    </Typography>
                </Box>
            </Box>
        );
    };

    const fetchHistoricoClientes = async () => {
        if (!userDetails?.uid) return;
        
        try {
            let coleccionRef;
            
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                coleccionRef = collection(db, 'userDetails', userDetails.uid, 'historico_clientes');
            } else if (['propietario_centro', 'entrenador_centro'].includes(userDetails.tipo_profesional) && userDetails.IDBO) {
                coleccionRef = collection(db, 'Centros', userDetails.IDBO, 'historico_clientes');
            } else {
                throw new Error('Tipo de usuario no válido o IDBO faltante');
            }

            const historicoSnap = await getDocs(query(
                coleccionRef,
                orderBy('fecha_actualizacion', 'desc'),
                limit(12)
            ));

            const datos = [];
            historicoSnap.forEach(doc => {
                const [mes, año] = doc.id.split('-');
                const nombreMes = new Date(año, parseInt(mes) - 1).toLocaleString('es', { month: 'short' });
                
                datos.push({
                    name: `${nombreMes} ${año}`,
                    total: doc.data().total_clientes
                });
            });

            datos.reverse();

            const mesesFaltantes = datos.length < 6 ? 6 - datos.length : 0;

            const mesActual = datos[datos.length - 1];
            const mesAnterior = datos[datos.length - 2];
            const variacion = mesAnterior ? 
                ((mesActual.total - mesAnterior.total) / mesAnterior.total) * 100 : 0;

            setClientesStats({
                historico: datos,
                mesActual: {
                    total: mesActual?.total || 0,
                    variacion: Math.round(variacion * 10) / 10
                },
                mesesFaltantes
            });

        } catch (error) {
            console.error("Error al obtener histórico de clientes:", error);
        }
    };

    const ClientesChart = ({ data, mesesFaltantes }) => (
        <Paper elevation={2} sx={{ 
            p: 3, 
            borderRadius: 4,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            height: 400,
            position: 'relative',
            overflow: 'hidden'
        }}>
            <Typography variant="h6" gutterBottom>
                Evolución de Clientes
            </Typography>
            {data.length > 0 ? (
                <Box sx={{ height: mesesFaltantes > 0 ? '85%' : '90%' }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis 
                                dataKey="name"
                                tick={{ fontSize: 12 }}
                                angle={-45}
                                textAnchor="end"
                                height={60}
                            />
                            <YAxis />
                            <Tooltip />
                            <Line 
                                type="monotone" 
                                dataKey="total" 
                                stroke="#FF5722" 
                                strokeWidth={2}
                                dot={{ r: 4 }}
                                activeDot={{ r: 6 }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </Box>
            ) : (
                <Box sx={{ 
                    height: '100%', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center' 
                }}>
                    <Typography variant="body1" color="text.secondary">
                        No hay datos históricos disponibles
                    </Typography>
                </Box>
            )}
            
            {mesesFaltantes > 0 && (
                <Box sx={{ 
                    position: 'absolute',
                    bottom: 16,
                    left: 16,
                    right: 16,
                    backgroundColor: 'rgba(255, 87, 34, 0.05)',
                    borderRadius: 2,
                    p: 1.5,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    border: '1px dashed rgba(255, 87, 34, 0.2)'
                }}>
                    <QueryStatsIcon sx={{ color: '#FF5722', fontSize: 20 }} />
                    <Box>
                        <Typography 
                            variant="subtitle2" 
                            sx={{ 
                                color: '#FF5722',
                                fontWeight: 600,
                                fontSize: '0.75rem',
                                lineHeight: 1
                            }}
                        >
                            Datos en Proceso
                        </Typography>
                        <Typography 
                            variant="body2" 
                            sx={{ 
                                color: 'text.secondary',
                                fontSize: '0.7rem',
                                lineHeight: 1.2
                            }}
                        >
                            Faltan {mesesFaltantes} {mesesFaltantes === 1 ? 'mes' : 'meses'} más para mostrar estadísticas completas
                        </Typography>
                    </Box>
                </Box>
            )}
        </Paper>
    );

    return (
        <Box 
            sx={{ 
                flexGrow: 1, 
                p: 3, 
                position: 'relative',
                minHeight: 'calc(100vh - 64px)',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: `url(${homeBackground})`,
                    backgroundSize: '50%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    opacity: 0.2,
                    zIndex: -1,
                }
            }}
        >
            <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                mb: 4,
                position: 'relative'
            }}>
                <Box
                    sx={{
                        position: 'relative',
                        width: 80,
                        height: 80,
                        mr: 3,
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: -4,
                            left: -4,
                            right: -4,
                            bottom: -4,
                            background: 'linear-gradient(45deg, #FF5722, #FFA000)',
                            borderRadius: '50%',
                            filter: 'blur(8px)',
                            opacity: 0.7,
                            animation: 'glow 4s ease-in-out infinite alternate',
                        },
                        '@keyframes glow': {
                            '0%': {
                                opacity: 0.5,
                                transform: 'scale(1)',
                            },
                            '50%': {
                                opacity: 0.7,
                                transform: 'scale(1.05)',
                            },
                            '100%': {
                                opacity: 0.5,
                                transform: 'scale(1)',
                            },
                        },
                    }}
                >
                    <Avatar
                        src={userDetails?.photo_url}
                        alt={userDetails?.name}
                        sx={{
                            width: 80,
                            height: 80,
                            border: '4px solid white',
                            boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                        }}
                    />
                </Box>
                <Box>
                    <Typography variant="h4" gutterBottom>
                        {`Bienvenido${userDetails ? `, ${userDetails.name}` : ''}`}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        Resumen de tu negocio como entrenador personal
                    </Typography>
                </Box>
            </Box>

            <Paper 
                elevation={3} 
                sx={{ 
                    mb: 4, 
                    p: 3, 
                    borderRadius: 4, 
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    position: 'relative',
                    overflow: 'hidden',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: -50,
                        left: -50,
                        right: -50,
                        bottom: -50,
                        background: 'radial-gradient(circle, rgba(255,87,34,0.2) 0%, rgba(255,87,34,0) 70%)',
                        animation: 'glow 5s infinite alternate',
                    },
                    '@keyframes glow': {
                        '0%': { transform: 'translate(10%, 10%)' },
                        '100%': { transform: 'translate(-10%, -10%)' },
                    },
                }}
            >
                <Typography variant="h5" gutterBottom>
                    Conoce tu negocio
                </Typography>
                <form onSubmit={handleChatSubmit}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Pregunta lo que quieras de la plataforma y del negocio"
                        value={chatInput}
                        onChange={(e) => setChatInput(e.target.value)}
                        disabled={isLoading}
                        InputProps={{
                            endAdornment: (
                                <Box sx={{ mr: 1 }}>
                                    <IconButton 
                                        type="submit" 
                                        edge="end"
                                        disabled={isLoading}
                                        sx={{
                                            backgroundColor: '#FF5722',
                                            color: 'white',
                                            borderRadius: '50%',
                                            width: 40,
                                            height: 40,
                                            '&:hover': {
                                                backgroundColor: '#FF7043',
                                                transform: 'scale(1.05)',
                                            },
                                            transition: 'all 0.3s ease-in-out',
                                        }}
                                    >
                                        {isLoading ? 
                                            <CircularProgress size={24} sx={{ color: 'white' }} /> : 
                                            <NorthRoundedIcon sx={{ fontSize: 20 }} />
                                        }
                                    </IconButton>
                                </Box>
                            ),
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: 4,
                                backgroundColor: 'white',
                                '& fieldset': {
                                    borderColor: 'rgba(0, 0, 0, 0.23)',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#FF5722',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#FF5722',
                                },
                            }
                        }}
                    />
                </form>
            </Paper>

            <Paper 
                elevation={3} 
                sx={{ 
                    mb: 4, 
                    p: 3, 
                    borderRadius: 4, 
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    position: 'relative',
                    overflow: 'hidden',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: -50,
                        left: -50,
                        right: -50,
                        bottom: -50,
                        background: 'radial-gradient(circle, rgba(255,87,34,0.1) 0%, rgba(255,87,34,0) 70%)',
                        animation: 'pulse 5s infinite alternate',
                    },
                    '@keyframes pulse': {
                        '0%': { opacity: 0.6 },
                        '100%': { opacity: 1 },
                    },
                }}
            >
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    mb: 2
                }}>
                    <Typography 
                        variant="h5" 
                        sx={{ 
                            color: 'text.primary'
                        }}
                    >
                        Resumen de hoy
                    </Typography>
                    {dailySummaryData && (
                        <Button
                            onClick={generateDailySummary}
                            startIcon={<RefreshIcon />}
                            sx={{ 
                                color: '#FF5722',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 87, 34, 0.1)'
                                }
                            }}
                        >
                            Actualizar
                        </Button>
                    )}
                </Box>
                
                {isLoadingSummary ? (
                    <LoadingAnimation />
                ) : summaryError ? (
                    <Typography color="error">{summaryError}</Typography>
                ) : !dailySummaryData ? (
                    <Box sx={{ textAlign: 'center', p: 2 }}>
                        <Typography gutterBottom>
                            No hay resumen disponible para hoy
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={generateDailySummary}
                            sx={{
                                bgcolor: '#FF5722',
                                '&:hover': { bgcolor: '#E64A19' }
                            }}
                        >
                            Generar Resumen Diario
                        </Button>
                    </Box>
                ) : (
                    renderSummaryContent()
                )}
            </Paper>

            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                closeAfterTransition
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={isModalOpen}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        maxWidth: 800,
                        maxHeight: '80vh',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        overflow: 'auto',
                    }}>
                        <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#FF5722' }}>
                            Respuesta del Asistente
                        </Typography>
                        <ReactMarkdown>
                            {chatResponse}
                        </ReactMarkdown>
                        <Button 
                            onClick={() => setIsModalOpen(false)}
                            sx={{ 
                                mt: 3, 
                                bgcolor: '#FF5722', 
                                color: 'white',
                                '&:hover': {
                                    bgcolor: '#E64A19',
                                }
                            }}
                        >
                            Cerrar
                        </Button>
                    </Box>
                </Fade>
            </Modal>

            <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                    <ClientesChart 
                        data={clientesStats.historico} 
                        mesesFaltantes={clientesStats.mesesFaltantes}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <InfoCard 
                                title="Total Clientes" 
                                value={clientesStats.mesActual.total.toString()} 
                                icon={<PeopleIcon sx={{ fontSize: 40, color: '#FF5722', opacity: 0.7 }} />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InfoCard 
                                title="Variación Mensual" 
                                value={
                                    clientesStats.mesActual.variacion > 0 
                                        ? `+${clientesStats.mesActual.variacion}`
                                        : clientesStats.mesActual.variacion.toString()
                                }
                                icon={
                                    clientesStats.mesActual.variacion > 0 
                                        ? <TrendingUpIcon sx={{ fontSize: 40, color: '#4CAF50', opacity: 0.7 }} />
                                        : clientesStats.mesActual.variacion < 0
                                            ? <TrendingDownIcon sx={{ fontSize: 40, color: '#f44336', opacity: 0.7 }} />
                                            : <TrendingFlatIcon sx={{ fontSize: 40, color: '#757575', opacity: 0.7 }} />
                                }
                                suffix="%"
                                color={
                                    clientesStats.mesActual.variacion > 0 
                                        ? 'success.main'
                                        : clientesStats.mesActual.variacion < 0
                                            ? 'error.main'
                                            : 'text.secondary'
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* Espacio adicional al final */}
            <Box sx={{ height: 100 }} />

            <Dialog open={openModal} onClose={handleCloseModal}>
                <DialogTitle>Funcionalidad en desarrollo</DialogTitle>
                <DialogContent>
                    <Typography>
                        Estamos trabajando en esta funcionalidad. Pronto podrás hacer preguntas sobre tu negocio y obtener respuestas instantáneas.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Home;
