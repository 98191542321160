import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, CircularProgress, Button } from '@mui/material';

const StripeConnectRefresh = () => {
  const { userId } = useParams();
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    const refreshLink = async () => {
      try {
        const response = await fetch('https://europe-southwest1-fitai-2cd85.cloudfunctions.net/create-account-link', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            userId: userId
          })
        });

        const data = await response.json();

        if (!response.ok) throw new Error(data.error);

        window.location.href = data.url;

      } catch (error) {
        console.error('Error refreshing link:', error);
        setStatus('error');
      }
    };

    refreshLink();
  }, [userId]);

  return (
    <Box sx={{
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      bgcolor: 'background.default',
      p: 3
    }}>
      {status === 'loading' ? (
        <>
          <CircularProgress sx={{ color: '#FF5722', mb: 3 }} />
          <Typography variant="h5" gutterBottom>
            Actualizando tu sesión...
          </Typography>
          <Typography color="text.secondary">
            Serás redirigido automáticamente
          </Typography>
        </>
      ) : (
        <Box textAlign="center">
          <Typography color="error" gutterBottom>
            Ha ocurrido un error al actualizar tu sesión
          </Typography>
          <Button 
            variant="contained"
            onClick={() => window.location.reload()}
            sx={{
              bgcolor: '#FF5722',
              '&:hover': {
                bgcolor: '#FF7043'
              }
            }}
          >
            Reintentar
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default StripeConnectRefresh;
