import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Box,
    Chip,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Divider
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import TimerIcon from '@mui/icons-material/Timer';
import SpeedIcon from '@mui/icons-material/Speed';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

const ActivityDetailModal = ({ open, onClose, activity }) => {
    if (!activity) return null;

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    background: 'linear-gradient(to bottom, #ffffff, #f8f9fa)'
                }
            }}
        >
            <DialogTitle sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                borderBottom: '1px solid rgba(0,0,0,0.12)'
            }}>
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                    {activity.actividad}
                </Typography>
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                    {/* Información General */}
                    <Grid item xs={12}>
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="subtitle2" color="text.secondary">
                                {activity.fecha ? format(activity.fecha.toDate(), 'PPpp', { locale: es }) : ''}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 3 }}>
                            <Chip
                                icon={<TimerIcon />}
                                label={`${activity.duracion} min`}
                                color="primary"
                                size="small"
                            />
                            <Chip
                                icon={<SpeedIcon />}
                                label={`Intensidad ${activity.intensidad}`}
                                color="secondary"
                                size="small"
                            />
                            {activity.kmrecorridos > 0 && (
                                <Chip
                                    icon={<SpeedIcon />}
                                    label={`${activity.kmrecorridos} km`}
                                    color="info"
                                    size="small"
                                />
                            )}
                            {activity.kgtotales > 0 && (
                                <Chip
                                    icon={<FitnessCenterIcon />}
                                    label={`${activity.kgtotales} kg totales`}
                                    color="success"
                                    size="small"
                                />
                            )}
                        </Box>
                    </Grid>

                    {/* Ejercicios */}
                    {activity.ejs && activity.ejs.length > 0 && (
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom sx={{ 
                                borderBottom: '2px solid #1976d2',
                                pb: 1,
                                mb: 2
                            }}>
                                Ejercicios Realizados
                            </Typography>
                            <List>
                                {activity.ejs.map((ejercicio, index) => (
                                    <React.Fragment key={index}>
                                        <ListItem sx={{ py: 1 }}>
                                            <ListItemText
                                                primary={ejercicio}
                                                secondary={`${activity.seriesporej[index] || '-'} series`}
                                            />
                                        </ListItem>
                                        {index < activity.ejs.length - 1 && <Divider />}
                                    </React.Fragment>
                                ))}
                            </List>
                        </Grid>
                    )}

                    {/* Estadísticas */}
                    <Grid item xs={12}>
                        <Box sx={{ 
                            mt: 2,
                            p: 2,
                            bgcolor: 'rgba(25, 118, 210, 0.05)',
                            borderRadius: 2
                        }}>
                            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
                                Resumen
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="text.secondary">
                                        Series Totales
                                    </Typography>
                                    <Typography variant="h6">
                                        {activity.seriestotales || 0}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="text.secondary">
                                        Músculos Trabajados
                                    </Typography>
                                    <Typography variant="h6">
                                        {activity.musculostrabajados || '-'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ActivityDetailModal; 