import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Confetti from 'react-confetti';
import logo from '../../assets/images/logo.png'; // Asegúrate de que la ruta sea correcta
import { useAuth } from '../../contexts/AuthContext'; // Añade esta importación

const SubscriptionConfirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userDetails, updateUserDetails } = useAuth();
  const [windowDimensions, setWindowDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [confettiRun, setConfettiRun] = useState(true);
  const [isValidSession, setIsValidSession] = useState(false);
  const [hasProcessedSession, setHasProcessedSession] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sessionId = params.get('session_id');
    
    if (sessionId && !hasProcessedSession) {
      setIsValidSession(true);
      setHasProcessedSession(true);
      
      // Solo actualizamos si tenemos los detalles del usuario
      if (userDetails) {
        updateUserDetails({ dashboardAccess: true });
      }
    } else if (!sessionId) {
      navigate('/home');
    }

    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    const timer = setTimeout(() => setConfettiRun(false), 5000);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timer);
    };
  }, []);

  const handleContinue = () => {
    navigate('/home', { replace: true });
  };

  if (!isValidSession) {
    return null;
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      backgroundColor: 'white',
      textAlign: 'center',
      position: 'relative',
      overflow: 'hidden',
      width: '100%'
    }}>
      {/* Confetti principal centrado */}
      <Confetti
        width={windowDimensions.width}
        height={windowDimensions.height}
        recycle={confettiRun}
        numberOfPieces={confettiRun ? 200 : 0}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none'
        }}
      />

      {/* Contenido */}
      <img src={logo} alt="Logo" style={{ maxWidth: '200px', marginBottom: '2rem', position: 'relative', zIndex: 1 }} />
      <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', color: '#333', position: 'relative', zIndex: 1 }}>
        Bienvenido al futuro
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ color: '#666', maxWidth: '600px', margin: '0 auto 2rem', position: 'relative', zIndex: 1 }}>
        Vamos a llevar a tu negocio fitness al siguiente nivel.
      </Typography>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleContinue}
        sx={{ 
          fontSize: '1.2rem', 
          padding: '10px 30px',
          borderRadius: '30px',
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
          transition: 'all 0.3s ease',
          position: 'relative',
          zIndex: 1,
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 6px 8px rgba(0,0,0,0.15)',
          }
        }}
      >
        Estoy listo
      </Button>
    </Box>
  );
};

export default SubscriptionConfirmation;
