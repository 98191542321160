import React, { useState, useEffect } from 'react';
import { 
    IconButton, 
    Popover, 
    Box, 
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton as MuiIconButton
} from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CloseIcon from '@mui/icons-material/Close';

const tutorials = [
    {
        title: "¡Bienvenido a la plataforma!",
        videoUrl: "URL_VIDEO_BIENVENIDA"
    },
    {
        title: "Pantalla de inicio",
        videoUrl: "URL_DEL_VIDEO_1"
    },
    {
        title: "Pantalla de clases",
        videoUrl: "URL_DEL_VIDEO_2"
    },
    {
        title: "Lista de clientes y Clientes",
        videoUrl: "URL_DEL_VIDEO_3"
    },
    {
        title: "Vincularse con clientes",
        videoUrl: "URL_DEL_VIDEO_4"
    },
    {
        title: "Mensajes y comunicación con clientes",
        videoUrl: "URL_DEL_VIDEO_5"
    },
    {
        title: "Configurar tu IA",
        videoUrl: "URL_DEL_VIDEO_6"
    },
    {
        title: "Configuración",
        videoUrl: "URL_DEL_VIDEO_7"
    },
    {
        title: "Unirte a la comunidad",
        videoUrl: "URL_DEL_VIDEO_8"
    }
];

const TutorialButton = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [hasSeenWelcome, setHasSeenWelcome] = useState(
        localStorage.getItem('hasSeenWelcome') === 'true'
    );

    useEffect(() => {
        if (!hasSeenWelcome) {
            handleVideoSelect(tutorials[0]);
            localStorage.setItem('hasSeenWelcome', 'true');
            setHasSeenWelcome(true);
        }
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleVideoSelect = (video) => {
        setSelectedVideo(video);
    };

    const handleVideoClose = () => {
        setSelectedVideo(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'tutorial-popover' : undefined;

    return (
        <>
            <IconButton
                aria-label="tutorials"
                onClick={handleClick}
                sx={{
                    position: 'fixed',
                    top: 80,
                    right: 16,
                    bgcolor: 'rgba(244, 143, 177, 0.1)',
                    '&:hover': {
                        bgcolor: 'rgba(244, 143, 177, 0.2)',
                    },
                    zIndex: 1300,
                }}
            >
                <SchoolIcon sx={{ color: '#F48FB1' }} />
            </IconButton>
            
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box sx={{ p: 2, maxWidth: 350 }}>
                    <Typography variant="h6" gutterBottom>
                        Tutoriales
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        Aprende a usar la plataforma con nuestros videos tutoriales
                    </Typography>
                    <List>
                        {tutorials.map((tutorial, index) => (
                            <ListItem 
                                button 
                                key={index}
                                onClick={() => handleVideoSelect(tutorial)}
                            >
                                <ListItemIcon>
                                    <PlayCircleOutlineIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary={tutorial.title} />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Popover>

            <Dialog
                open={Boolean(selectedVideo)}
                onClose={handleVideoClose}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    {selectedVideo?.title}
                    <MuiIconButton
                        aria-label="close"
                        onClick={handleVideoClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </MuiIconButton>
                </DialogTitle>
                <DialogContent>
                    {selectedVideo && (
                        <Box sx={{ position: 'relative', paddingTop: '56.25%', width: '100%' }}>
                            <iframe
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                }}
                                src={selectedVideo.videoUrl}
                                title={selectedVideo.title}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default TutorialButton; 