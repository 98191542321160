import React, { createContext, useState } from 'react';

export const StripeContext = createContext();

export const StripeProvider = ({ children }) => {
    const [stripeAccountId, setStripeAccountId] = useState(null);
    const [accountStatus, setAccountStatus] = useState(null);

    const updateStripeAccount = async (accountData) => {
        try {
            setStripeAccountId(accountData.id);
            setAccountStatus(accountData.status);
        } catch (error) {
            console.error('Error updating Stripe account:', error);
        }
    };

    return (
        <StripeContext.Provider value={{
            stripeAccountId,
            accountStatus,
            updateStripeAccount
        }}>
            {children}
        </StripeContext.Provider>
    );
};
