import React, { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { Navigate, useLocation } from 'react-router-dom';
import RestrictedAccess from './RestrictedAccess';

const PrivateRoute = ({ children }) => {
    const { currentUser, dashboardAccess } = useContext(AuthContext);
    const location = useLocation();
    
    // Permitir acceso a la página de confirmación de suscripción sin redirecciones
    if (location.pathname === '/subscription-confirmation') {
        return children;
    }

    // Para otras rutas, verificar autenticación
    if (!currentUser) {
        return <Navigate to="/login" state={{ from: location }} />;
    }

    if (!dashboardAccess && !location.pathname.includes('/subscription-confirmation')) {
        return (
            <>
                {children}
                <RestrictedAccess />
            </>
        );
    }

    return children;
};

export default PrivateRoute;
