import React, { useState, useEffect, useContext } from 'react';
import { 
    Typography, Box, Paper, Grid, Button, TextField, 
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    IconButton, Dialog, DialogTitle, DialogContent, DialogActions,
    Tabs, Tab, Chip, Alert
} from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import { AuthContext } from '../../contexts/AuthContext';
import { db } from '../../config/firebaseConfig';
import { collection, query, where, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { StripeContext } from '../../contexts/StripeContext';
import { useLocation } from 'react-router-dom';
import { useStripeConnect } from '../../hooks/useStripeConnect';

const Facturacion = () => {
    const [facturas, setFacturas] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedFactura, setSelectedFactura] = useState(null);
    const { userDetails } = useContext(AuthContext);
    const [ingresosMensuales, setIngresosMensuales] = useState(0);
    const [facturasPendientes, setFacturasPendientes] = useState(0);
    const [crecimientoMensual, setCrecimientoMensual] = useState(0);
    const [tabValue, setTabValue] = useState(0);
    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const { stripeAccountId, accountStatus } = useContext(StripeContext);
    const [openStripeDialog, setOpenStripeDialog] = useState(false);
    const location = useLocation();
    const [stripeSetupComplete, setStripeSetupComplete] = useState(false);
    const [localLoading, setLocalLoading] = useState(false);
    const [localError, setLocalError] = useState(null);
    const { createConnectedAccount, loading: stripeLoading, error: stripeError } = useStripeConnect();

    // Datos de ejemplo para la gráfica de ingresos
    const datosIngresos = [
        { mes: 'Ene', membresias: 500, clases: 300, otros: 200 },
        { mes: 'Feb', membresias: 550, clases: 350, otros: 300 },
        { mes: 'Mar', membresias: 600, clases: 400, otros: 100 },
        { mes: 'Abr', membresias: 650, clases: 450, otros: 200 },
        { mes: 'May', membresias: 700, clases: 500, otros: 300 },
        { mes: 'Jun', membresias: 750, clases: 550, otros: 100 },
    ];

    // Datos de ejemplo para el gráfico circular
    const datosTiposIngresos = [
        { name: 'Membresías', value: 750 },
        { name: 'Clases', value: 550 },
        { name: 'Otros servicios', value: 100 },
    ];

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

    useEffect(() => {
        fetchFacturas();
    }, [userDetails]);

    useEffect(() => {
        if (location.state?.stripeSetupComplete) {
            setStripeSetupComplete(true);
            setTimeout(() => setStripeSetupComplete(false), 5000);
        }
    }, [location]);

    const fetchFacturas = async () => {
        // Aquí iría la lógica para obtener las facturas de Firestore
        // Por ahora, usaremos datos de ejemplo
        const facturasEjemplo = [
            { id: 1, cliente: 'Juan Pérez', monto: 100, fecha: '2023-05-01', estado: 'Pagada' },
            { id: 2, cliente: 'María García', monto: 150, fecha: '2023-05-15', estado: 'Pendiente' },
            // ... más facturas de ejemplo
        ];
        setFacturas(facturasEjemplo);
    };

    const handleOpenDialog = (factura = null) => {
        setSelectedFactura(factura);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setSelectedFactura(null);
        setOpenDialog(false);
    };

    const handleSaveFactura = (facturaData) => {
        // Aquí iría la lógica para guardar o actualizar la factura en Firestore
        console.log('Guardar factura:', facturaData);
        handleCloseDialog();
        fetchFacturas();
    };

    const handleDeleteFactura = (id) => {
        // Aquí iría la lógica para eliminar la factura de Firestore
        console.log('Eliminar factura:', id);
        fetchFacturas();
    };

    const filteredFacturas = facturas.filter(factura => 
        factura.cliente.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const InfoCard = ({ title, value, icon, change }) => (
        <Paper elevation={2} sx={{ 
            p: 3, 
            borderRadius: 4,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: 3,
            }
        }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" component="div" color="text.secondary">
                    {title}
                </Typography>
                {icon}
            </Box>
            <Typography variant="h4" component="div" sx={{ mb: 1 }}>
                {value}
            </Typography>
            {change && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TrendingUpIcon sx={{ color: 'success.main', mr: 1 }} />
                    <Typography variant="body2" color="success.main">
                        {change}% este mes
                    </Typography>
                </Box>
            )}
        </Paper>
    );

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleOpenInfoDialog = () => {
        setOpenInfoDialog(true);
    };

    const handleCloseInfoDialog = () => {
        setOpenInfoDialog(false);
    };

    const handleOpenStripeDialog = () => {
        setOpenStripeDialog(true);
    };

    const handleCloseStripeDialog = () => {
        setOpenStripeDialog(false);
    };

    // Determinar si mostrar la configuración de Stripe
    const showStripeSetup = !stripeAccountId || accountStatus !== 'active';

    const handleCreateStripeAccount = async () => {
        try {
            await createConnectedAccount();
        } catch (error) {
            console.error('Error al crear la cuenta de Stripe:', error);
        }
    };

    return (
        <Box sx={{ position: 'relative' }}>
            {stripeSetupComplete && (
                <Alert 
                    severity="success"
                    sx={{ 
                        position: 'fixed', 
                        top: 16, 
                        right: 16, 
                        zIndex: 9999 
                    }}
                >
                    ¡Configuración de pagos completada con éxito!
                </Alert>
            )}
            <Box 
                sx={{ 
                    flexGrow: 1, 
                    p: 3, 
                    position: 'relative',
                    minHeight: 'calc(100vh - 64px)',
                    filter: showStripeSetup ? 'blur(5px)' : 'none',
                }}
            >
                <Typography variant="h4" gutterBottom>
                    Facturación
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Gestiona los cobros y facturas de tu negocio
                </Typography>

                <Grid container spacing={4} sx={{ mb: 4 }}>
                    <Grid item xs={12} md={4}>
                        <InfoCard 
                            title="Ingresos Mensuales" 
                            value={`${ingresosMensuales}€`}
                            icon={<AttachMoneyIcon sx={{ fontSize: 40, color: 'primary.main', opacity: 0.7 }} />}
                            change={5}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InfoCard 
                            title="Facturas Pendientes" 
                            value={facturasPendientes}
                            icon={<ReceiptIcon sx={{ fontSize: 40, color: 'warning.main', opacity: 0.7 }} />}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InfoCard 
                            title="Crecimiento Mensual" 
                            value={`${crecimientoMensual}%`}
                            icon={<TrendingUpIcon sx={{ fontSize: 40, color: 'success.main', opacity: 0.7 }} />}
                            change={crecimientoMensual}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={4} sx={{ mb: 4 }}>
                    <Grid item xs={12} md={8}>
                        <Paper elevation={2} sx={{ 
                            p: 3, 
                            borderRadius: 4,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        }}>
                            <Typography variant="h6" gutterBottom>
                                Evolución de Ingresos
                            </Typography>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart data={datosIngresos}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="mes" />
                                    <YAxis />
                                    <Tooltip />
                                    <Line type="monotone" dataKey="membresias" stroke="#0088FE" strokeWidth={2} />
                                    <Line type="monotone" dataKey="clases" stroke="#00C49F" strokeWidth={2} />
                                    <Line type="monotone" dataKey="otros" stroke="#FFBB28" strokeWidth={2} />
                                </LineChart>
                            </ResponsiveContainer>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper elevation={2} sx={{ 
                            p: 3, 
                            borderRadius: 4,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            height: '100%',
                        }}>
                            <Typography variant="h6" gutterBottom>
                                Distribución de Ingresos
                            </Typography>
                            <ResponsiveContainer width="100%" height={250}>
                                <PieChart>
                                    <Pie
                                        data={datosTiposIngresos}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        outerRadius={80}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {datosTiposIngresos.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                </PieChart>
                            </ResponsiveContainer>
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                {datosTiposIngresos.map((entry, index) => (
                                    <Chip
                                        key={entry.name}
                                        label={entry.name}
                                        sx={{ 
                                            backgroundColor: COLORS[index % COLORS.length], 
                                            color: 'white',
                                            mr: 1 
                                        }}
                                    />
                                ))}
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>

                <Paper elevation={2} sx={{ 
                    p: 3, 
                    borderRadius: 4,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    mb: 4
                }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="facturación tabs">
                            <Tab icon={<CardMembershipIcon />} label="Membresías" />
                            <Tab icon={<FitnessCenterIcon />} label="Clases" />
                            <Tab icon={<MiscellaneousServicesIcon />} label="Otros Servicios" />
                        </Tabs>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => handleOpenDialog()}
                            sx={{
                                backgroundColor: '#FF5722',
                                '&:hover': {
                                    backgroundColor: '#FF7043',
                                },
                            }}
                        >
                            Nueva Factura
                        </Button>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                variant="outlined"
                                size="small"
                                placeholder="Buscar factura"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: 4,
                                        backgroundColor: 'white',
                                    }
                                }}
                            />
                            <IconButton sx={{ ml: 1 }}>
                                <SearchIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Cliente</TableCell>
                                    <TableCell align="right">Monto</TableCell>
                                    <TableCell align="right">Fecha</TableCell>
                                    <TableCell align="right">Estado</TableCell>
                                    <TableCell align="right">Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* Aquí irían las filas de la tabla filtradas según el tab seleccionado */}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                <Dialog open={openDialog} onClose={handleCloseDialog}>
                    <DialogTitle>{selectedFactura ? 'Editar Factura' : 'Nueva Factura'}</DialogTitle>
                    <DialogContent>
                        {/* Aquí iría el formulario para crear/editar facturas */}
                        <Typography>Formulario de factura en desarrollo</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Cancelar</Button>
                        <Button onClick={() => handleSaveFactura({})}>Guardar</Button>
                    </DialogActions>
                </Dialog>
            </Box>

            {/* Reemplazar el modal superpuesto existente con esta nueva versión */}
            {showStripeSetup && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    }}
                >
                    <Paper
                        sx={{
                            width: '80%',
                            maxWidth: 600,
                            p: 4,
                            outline: 'none',
                            borderRadius: '16px',
                            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                            textAlign: 'center',
                        }}
                    >
                        <Typography variant="h4" gutterBottom>
                            Configura tu cuenta de facturación
                        </Typography>
                        <Typography variant="body1" sx={{ mt: 2 }}>
                            Para comenzar a recibir pagos y gestionar tu facturación, necesitas conectar tu cuenta con Stripe.
                        </Typography>
                        <Button
                            variant="contained"
                            sx={{
                                mt: 3,
                                backgroundColor: '#FF5722',
                                color: 'white',
                                padding: '12px 24px',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                textTransform: 'none',
                                borderRadius: '30px',
                                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                transition: 'all 0.3s ease',
                                '&:hover': {
                                    backgroundColor: '#FF7043',
                                    boxShadow: '0 6px 10px rgba(0,0,0,0.15)',
                                    transform: 'translateY(-2px)',
                                },
                            }}
                            onClick={handleOpenStripeDialog}
                        >
                            Conectar con Stripe
                        </Button>
                        <Typography variant="body2" sx={{ mt: 2, fontSize: '0.8rem', color: 'text.secondary' }}>
                            Stripe es nuestra plataforma de pagos segura para gestionar todas tus transacciones.
                        </Typography>
                    </Paper>
                </Box>
            )}

            {/* Reemplazar el diálogo informativo existente con el nuevo componente de Stripe */}
            <Dialog open={openStripeDialog} onClose={handleCloseStripeDialog}>
                <DialogTitle>Configurar cuenta de Stripe</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        Para comenzar a recibir pagos, necesitas configurar tu cuenta de Stripe.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseStripeDialog}>
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleCreateStripeAccount}
                        disabled={stripeLoading}
                        sx={{
                            backgroundColor: '#FF5722',
                            '&:hover': {
                                backgroundColor: '#FF7043',
                            },
                        }}
                    >
                        {stripeLoading ? 'Configurando...' : 'Configurar Stripe'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Facturacion;
