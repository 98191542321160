import React, { useState, useContext, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Box,
    Typography,
    TextField,
    Select,
    MenuItem,
    Button,
    Switch,
    FormControlLabel,
    FormGroup,
    FormControl,
    RadioGroup,
    Radio,
    Stack,
    CircularProgress,
    Checkbox,
    Chip,
    DialogActions,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import {
    Close as CloseIcon,
    FitnessCenter as GymIcon,
    DirectionsRun as CardioIcon,
    Timer as IntervalsIcon,
    Mic as MicIcon,
    Stop as StopIcon,
    VideoLibrary as VideoIcon,
    Image as ImageIcon,
    Description as DocumentIcon,
    PictureAsPdf as PictureAsPdfIcon,
    Audiotrack as AudiotrackIcon
} from '@mui/icons-material';
import { AuthContext } from '../../contexts/AuthContext';
import { db } from '../../config/firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';

const ExerciseDetailModal = ({ open, onClose, exercise }) => {
    const [description, setDescription] = useState('');
    const [selectedResources, setSelectedResources] = useState([]);
    const [audioBlob, setAudioBlob] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [exerciseType, setExerciseType] = useState('gym');
    const [feedbackRequired, setFeedbackRequired] = useState(false);
    const [effortRequired, setEffortRequired] = useState(false);
    const [effortType, setEffortType] = useState('rir');
    const { userDetails } = useContext(AuthContext);
    const [recursos, setRecursos] = useState([]);
    const [loadingRecursos, setLoadingRecursos] = useState(true);
    const [openResourcesModal, setOpenResourcesModal] = useState(false);

    // Estados específicos por tipo
    const [gymConfig, setGymConfig] = useState({
        sets: 4,
        reps: 12,
        weight: 0
    });
    const [cardioConfig, setCardioConfig] = useState({
        duration: 30,
        intensity: 'moderate'
    });
    const [intervalsConfig, setIntervalsConfig] = useState({
        rounds: 4,
        workTime: 40,
        restTime: 20,
    });

    useEffect(() => {
        fetchRecursos();
    }, [userDetails]);

    const fetchRecursos = async () => {
        if (!userDetails) return;

        try {
            let recursosRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                recursosRef = collection(db, 'userDetails', userDetails.uid, 'recursos');
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                if (!userDetails.IDBO) {
                    throw new Error('No se encontró el ID del centro');
                }
                recursosRef = collection(db, 'Centros', userDetails.IDBO, 'recursos');
            } else {
                throw new Error('Tipo de profesional no válido');
            }

            const recursosSnapshot = await getDocs(recursosRef);
            const recursosData = recursosSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            setRecursos(recursosData);
        } catch (error) {
            console.error("Error al cargar los recursos:", error);
        } finally {
            setLoadingRecursos(false);
        }
    };

    const handleRecording = () => {
        if (isRecording) {
            setIsRecording(false);
        } else {
            setIsRecording(true);
        }
    };

    const renderExerciseConfig = () => {
        switch (exerciseType) {
            case 'gym':
                return (
                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2 }}>
                        <TextField
                            label="Series"
                            type="number"
                            value={gymConfig.sets}
                            onChange={(e) => setGymConfig({...gymConfig, sets: e.target.value})}
                            InputProps={{ inputProps: { min: 1 } }}
                        />
                        <TextField
                            label="Repeticiones"
                            type="number"
                            value={gymConfig.reps}
                            onChange={(e) => setGymConfig({...gymConfig, reps: e.target.value})}
                            InputProps={{ inputProps: { min: 1 } }}
                        />
                        <TextField
                            label="Peso (kg)"
                            type="number"
                            value={gymConfig.weight}
                            onChange={(e) => setGymConfig({...gymConfig, weight: e.target.value})}
                            InputProps={{ inputProps: { min: 0 } }}
                        />
                    </Box>
                );
            case 'cardio':
                return (
                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
                        <TextField
                            label="Duración (min)"
                            type="number"
                            value={cardioConfig.duration}
                            onChange={(e) => setCardioConfig({...cardioConfig, duration: e.target.value})}
                            InputProps={{ inputProps: { min: 1 } }}
                        />
                        <FormControl fullWidth>
                            <Select
                                value={cardioConfig.intensity}
                                onChange={(e) => setCardioConfig({...cardioConfig, intensity: e.target.value})}
                            >
                                <MenuItem value="low">Baja</MenuItem>
                                <MenuItem value="moderate">Moderada</MenuItem>
                                <MenuItem value="high">Alta</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                );
            case 'intervals':
                return (
                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2 }}>
                        <TextField
                            label="Rounds"
                            type="number"
                            value={intervalsConfig.rounds}
                            onChange={(e) => setIntervalsConfig({...intervalsConfig, rounds: e.target.value})}
                            InputProps={{ inputProps: { min: 1 } }}
                        />
                        <TextField
                            label="Trabajo (seg)"
                            type="number"
                            value={intervalsConfig.workTime}
                            onChange={(e) => setIntervalsConfig({...intervalsConfig, workTime: e.target.value})}
                            InputProps={{ inputProps: { min: 5 } }}
                        />
                        <TextField
                            label="Descanso (seg)"
                            type="number"
                            value={intervalsConfig.restTime}
                            onChange={(e) => setIntervalsConfig({...intervalsConfig, restTime: e.target.value})}
                            InputProps={{ inputProps: { min: 0 } }}
                        />
                    </Box>
                );
            default:
                return null;
        }
    };

    const sectionStyle = {
        mb: 3,
        p: 3,
        borderRadius: 2,
        backgroundColor: 'background.paper',
        boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
        transition: 'all 0.3s ease',
        '&:hover': {
            boxShadow: '0 4px 12px rgba(0,0,0,0.12)'
        }
    };

    // Modal de selección de recursos
    const ResourceSelectionModal = () => (
        <Dialog 
            open={openResourcesModal} 
            onClose={() => setOpenResourcesModal(false)}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Seleccionar Recursos</DialogTitle>
            <DialogContent>
                {loadingRecursos ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                        <CircularProgress />
                    </Box>
                ) : recursos.length > 0 ? (
                    <List sx={{ 
                        width: '100%',
                        bgcolor: 'background.paper',
                        borderRadius: 1,
                    }}>
                        {recursos.map((recurso) => (
                            <ListItem
                                key={recurso.id}
                                sx={{
                                    borderRadius: 1,
                                    mb: 0.5,
                                    '&:hover': {
                                        bgcolor: 'action.hover',
                                    },
                                }}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        checked={selectedResources.includes(recurso.id)}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setSelectedResources([...selectedResources, recurso.id]);
                                            } else {
                                                setSelectedResources(selectedResources.filter(id => id !== recurso.id));
                                            }
                                        }}
                                    />
                                }
                            >
                                <ListItemIcon>
                                    {recurso.fileType === 'video' && <VideoIcon color="primary" />}
                                    {recurso.fileType === 'imagen' && <ImageIcon color="primary" />}
                                    {recurso.fileType === 'pdf' && <PictureAsPdfIcon color="error" />}
                                    {recurso.fileType === 'audio' && <AudiotrackIcon color="primary" />}
                                </ListItemIcon>
                                <ListItemText 
                                    primary={recurso.titulo}
                                    secondary={
                                        <Chip 
                                            label={recurso.categoria} 
                                            size="small" 
                                            sx={{ mt: 0.5 }}
                                        />
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Box sx={{ textAlign: 'center', py: 3 }}>
                        <Typography color="text.secondary">
                            No hay recursos disponibles
                        </Typography>
                    </Box>
                )}
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button onClick={() => setOpenResourcesModal(false)}>
                    Cancelar
                </Button>
                <Button 
                    variant="contained" 
                    onClick={() => setOpenResourcesModal(false)}
                    color="primary"
                >
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    bgcolor: '#f5f5f5'
                }
            }}
        >
            <DialogTitle sx={{
                m: 0,
                p: 2,
                bgcolor: 'primary.main',
                color: 'white',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Typography variant="h6">Configurar Ejercicio</Typography>
                <IconButton onClick={onClose} sx={{ color: 'white' }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ p: 3 }}>
                {/* Tipo de ejercicio */}
                <Box sx={sectionStyle}>
                    <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
                        Tipo de Ejercicio
                    </Typography>
                    <Stack direction="row" spacing={2}>
                        {[
                            { value: 'gym', icon: <GymIcon />, label: 'Gimnasio' },
                            { value: 'cardio', icon: <CardioIcon />, label: 'Cardio' },
                            { value: 'intervals', icon: <IntervalsIcon />, label: 'Intervalos' }
                        ].map((type) => (
                            <Button
                                key={type.value}
                                variant={exerciseType === type.value ? 'contained' : 'outlined'}
                                startIcon={type.icon}
                                onClick={() => setExerciseType(type.value)}
                                sx={{ 
                                    flex: 1,
                                    py: 1.5,
                                    borderRadius: 2,
                                    backgroundColor: exerciseType === type.value ? 'primary.main' : 'transparent'
                                }}
                            >
                                {type.label}
                            </Button>
                        ))}
                    </Stack>
                </Box>

                {/* Descripción */}
                <Box sx={sectionStyle}>
                    <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
                        Descripción
                    </Typography>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Describe el ejercicio y su técnica correcta..."
                        sx={{ bgcolor: 'white' }}
                    />
                </Box>

                {/* Recursos */}
                <Box sx={sectionStyle}>
                    <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
                        Recursos
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Button
                            variant="outlined"
                            onClick={() => setOpenResourcesModal(true)}
                            startIcon={<VideoIcon />}
                            sx={{ py: 1.5 }}
                        >
                            Seleccionar Recursos
                        </Button>
                        {selectedResources.length > 0 && (
                            <Box sx={{ 
                                display: 'flex', 
                                flexWrap: 'wrap', 
                                gap: 1,
                                p: 2,
                                bgcolor: 'background.paper',
                                borderRadius: 1
                            }}>
                                {selectedResources.map(resourceId => {
                                    const recurso = recursos.find(r => r.id === resourceId);
                                    return recurso && (
                                        <Chip
                                            key={recurso.id}
                                            label={recurso.titulo}
                                            onDelete={() => setSelectedResources(prev => 
                                                prev.filter(id => id !== recurso.id)
                                            )}
                                            icon={
                                                recurso.fileType === 'video' ? <VideoIcon /> :
                                                recurso.fileType === 'imagen' ? <ImageIcon /> :
                                                recurso.fileType === 'pdf' ? <PictureAsPdfIcon /> :
                                                <AudiotrackIcon />
                                            }
                                        />
                                    );
                                })}
                            </Box>
                        )}
                    </Box>
                </Box>

                {/* Audio explicación */}
                <Box sx={sectionStyle}>
                    <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
                        Explicación por Audio
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, bgcolor: 'white', p: 2, borderRadius: 1 }}>
                        <IconButton 
                            color={isRecording ? 'error' : 'primary'}
                            onClick={handleRecording}
                            sx={{ 
                                width: 56,
                                height: 56,
                                bgcolor: isRecording ? 'error.light' : 'primary.light',
                                '&:hover': {
                                    bgcolor: isRecording ? 'error.main' : 'primary.main',
                                }
                            }}
                        >
                            {isRecording ? <StopIcon /> : <MicIcon />}
                        </IconButton>
                        {audioBlob && (
                            <audio controls src={URL.createObjectURL(audioBlob)} style={{ flexGrow: 1 }} />
                        )}
                        {!audioBlob && !isRecording && (
                            <Typography color="text.secondary">
                                Haz clic en el micrófono para empezar a grabar
                            </Typography>
                        )}
                    </Box>
                </Box>

                {/* Configuración específica */}
                <Box sx={sectionStyle}>
                    <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
                        Configuración
                    </Typography>
                    {renderExerciseConfig()}
                </Box>

                {/* Configuración de feedback */}
                <Box sx={sectionStyle}>
                    <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
                        Configuración de Feedback
                    </Typography>
                    <FormGroup sx={{ mb: 2 }}>
                        <FormControlLabel
                            control={
                                <Switch 
                                    checked={feedbackRequired}
                                    onChange={(e) => setFeedbackRequired(e.target.checked)}
                                />
                            }
                            label="Requerir nota del usuario"
                        />
                        <FormControlLabel
                            control={
                                <Switch 
                                    checked={effortRequired}
                                    onChange={(e) => setEffortRequired(e.target.checked)}
                                />
                            }
                            label="Requerir esfuerzo percibido"
                        />
                    </FormGroup>
                    
                    {effortRequired && (
                        <FormControl component="fieldset">
                            <RadioGroup
                                value={effortType}
                                onChange={(e) => setEffortType(e.target.value)}
                            >
                                <FormControlLabel value="rir" control={<Radio />} label="RIR (Repeticiones en Reserva)" />
                                <FormControlLabel value="rpe" control={<Radio />} label="RPE (Esfuerzo Percibido)" />
                                <FormControlLabel value="percentage" control={<Radio />} label="Porcentaje de 1RM" />
                            </RadioGroup>
                        </FormControl>
                    )}
                </Box>

                {/* Renderiza el modal de selección */}
                <ResourceSelectionModal />
            </DialogContent>
        </Dialog>
    );
};

export default ExerciseDetailModal;