import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Box,
    Paper,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
} from '@mui/material';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import GroupIcon from '@mui/icons-material/Group';
import CategoryIcon from '@mui/icons-material/Category';
import DescriptionIcon from '@mui/icons-material/Description';
import PersonIcon from '@mui/icons-material/Person';

const GroupClassSummaryModal = ({ open, onClose, classData }) => {
    if (!classData) return null;

    const infoItemStyle = {
        display: 'flex',
        alignItems: 'center',
        mb: 1,
    };

    const iconStyle = {
        color: '#FF5722',
        mr: 1,
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                style: {
                    borderRadius: '20px',
                    overflow: 'hidden',
                },
            }}
        >
            <Paper elevation={0} sx={{ 
                p: 3,
                background: 'linear-gradient(45deg, #FF5722, #FFA000)',
                color: 'white'
            }}>
                <DialogTitle sx={{ p: 0, fontSize: '24px', fontWeight: 'bold' }}>
                    Resumen de Clase Grupal
                </DialogTitle>
            </Paper>
            <DialogContent sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Paper elevation={2} sx={{ p: 2, borderRadius: 2 }}>
                                <Typography variant="h6" sx={{ color: '#FF5722', fontWeight: 'bold', mb: 2 }}>
                                    {classData.nombreClase}
                                </Typography>
                                <Box sx={infoItemStyle}>
                                    <CalendarTodayIcon sx={iconStyle} />
                                    <Typography variant="body1">
                                        <strong>Fecha/Día:</strong> {classData.Tipo === 'periodica' ? classData.Dia : (classData.Fecha ? format(classData.Fecha, 'dd MMMM yyyy', { locale: es }) : 'N/A')}
                                    </Typography>
                                </Box>
                                <Box sx={infoItemStyle}>
                                    <AccessTimeIcon sx={iconStyle} />
                                    <Typography variant="body1">
                                        <strong>Hora:</strong> {classData.Hora ? format(classData.Hora, 'HH:mm') : 'N/A'}
                                    </Typography>
                                </Box>
                                <Box sx={infoItemStyle}>
                                    <FitnessCenterIcon sx={iconStyle} />
                                    <Typography variant="body1">
                                        <strong>Duración:</strong> {classData.Duracion} minutos
                                    </Typography>
                                </Box>
                                <Box sx={infoItemStyle}>
                                    <GroupIcon sx={iconStyle} />
                                    <Typography variant="body1">
                                        <strong>Capacidad:</strong> {classData.clientes.length}/{classData.CapacidadMaxima}
                                    </Typography>
                                </Box>
                                <Box sx={infoItemStyle}>
                                    <FitnessCenterIcon sx={iconStyle} />
                                    <Typography variant="body1">
                                        <strong>Dificultad:</strong> {classData.Dificultad}
                                    </Typography>
                                </Box>
                                <Box sx={infoItemStyle}>
                                    <CategoryIcon sx={iconStyle} />
                                    <Typography variant="body1">
                                        <strong>Categorías:</strong> {classData.Categorias || 'N/A'}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper elevation={2} sx={{ p: 2, borderRadius: 2 }}>
                                <Typography variant="h6" sx={{ color: '#FF5722', fontWeight: 'bold', mb: 2 }}>
                                    Participantes Inscritos
                                </Typography>
                                <List>
                                    {classData.clientes.map((cliente, index) => (
                                        <ListItem key={index}>
                                            <ListItemIcon>
                                                <PersonIcon sx={iconStyle} />
                                            </ListItemIcon>
                                            <ListItemText 
                                                primary={cliente.nombre}
                                                secondary={cliente.email}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                    
                    {classData.Descripcion && (
                        <Paper elevation={2} sx={{ p: 2, mt: 3, borderRadius: 2 }}>
                            <Typography variant="h6" gutterBottom sx={{ color: '#FF5722' }}>
                                Descripción
                            </Typography>
                            <Typography variant="body1">
                                {classData.Descripcion}
                            </Typography>
                        </Paper>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default GroupClassSummaryModal;
