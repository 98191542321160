// src/contexts/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { auth, db } from '../config/firebaseConfig';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc, getDoc, serverTimestamp, collection, onSnapshot } from 'firebase/firestore';
import { generateIDPT, generateIDBOUnico } from '../utils/idGenerators'; // Asumimos que crearemos esta utilidad

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [dashboardAccess, setDashboardAccess] = useState(false);

    useEffect(() => {
        const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
            setCurrentUser(user);
            if (user) {
                const userDocRef = doc(db, 'userDetails', user.uid);
                const unsubscribeSnapshot = onSnapshot(userDocRef, (docSnap) => {
                    if (docSnap.exists()) {
                        const data = docSnap.data();
                        setUserDetails(data);
                        setDashboardAccess(data.dashboardAccess || false);
                    } else {
                        setUserDetails(null);
                        setDashboardAccess(false);
                    }
                });

                return () => unsubscribeSnapshot();
            } else {
                setUserDetails(null);
                setDashboardAccess(false);
            }
        });

        return () => unsubscribeAuth();
    }, []);

    const generarIDPT = async () => {
        return generateIDPT();
    };

    const generarIdBOUnico = async () => {
        return generateIDBOUnico();
    };

    const login = async (email, password) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            return userCredential.user;
        } catch (error) {
            console.error("Error en el inicio de sesión:", error);
            throw error;
        }
    };

    const register = async (email, password, displayName, name, apellidos, tipoProfesional, nombreBusiness, idBO, idpt, phoneNumber) => {
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            const userDetailsData = {
                uid: user.uid,
                email,
                display_name: displayName,
                name,
                apellidos,
                photo_url: '',
                created_time: new Date(),
                phone_number: phoneNumber,
                tipo_profesional: tipoProfesional,
                tipoplan: 'basico',
                es_entrenador: true,
                nombre_business: nombreBusiness,
                IDPT: idpt,
                IDBO: idBO,
                cambiarrutina: [],
                cambiarobjetivos: [],
                cambiardieta: [],
                clientes: [],
                solicitudes: []
            };

            await setDoc(doc(db, 'userDetails', user.uid), userDetailsData);

            // Crear documento en Codigos_entrenadores
            await setDoc(doc(db, 'Codigos_entrenadores', idpt), {
                IDPT: idpt,
                IDBO: tipoProfesional === 'entrenador_independiente' ? null : idBO,
                uidentrenador: user.uid
            });

            if (tipoProfesional === 'propietario_centro') {
                await createCentroDocument(nombreBusiness, idBO, idpt);
            }

            // Establecer el usuario actual después del registro
            setCurrentUser(user);
            setUserDetails(userDetailsData);

            return userDetailsData;
        } catch (error) {
            console.error('Error al registrarse:', error);
            throw error;
        }
    };

    const createCentroDocument = async (nombreCentro, idBO, idpt) => {
        try {
            const centroRef = doc(db, 'Centros', idBO);  // Usamos idBO como ID del documento
            await setDoc(centroRef, {
                IDBO: idBO,
                propietarios: [idpt],
                trabajadores: [],
                clientes: [],
                nombreCentro: nombreCentro,
                descripcion: '',
                photo_url: ''
            });
            console.log('Documento del centro creado con éxito');
        } catch (error) {
            console.error('Error al crear el documento del centro:', error);
            throw error;
        }
    };

    const logout = async () => {
        try {
            await signOut(auth);
        } catch (error) {
            console.error("Error al cerrar sesión:", error);
            throw error;
        }
    };

    const verificarIdBO = async (idBO) => {
        // Implementa la lógica para verificar si el IDBO existe en la base de datos
        const centrosRef = doc(db, 'Centros', idBO);
        const centroDoc = await getDoc(centrosRef);
        return centroDoc.exists();
    };

    const updateUserDetails = async (newDetails) => {
        if (currentUser) {
            try {
                const userRef = doc(db, 'userDetails', currentUser.uid);
                await setDoc(userRef, newDetails, { merge: true });
                setUserDetails(prevDetails => ({...prevDetails, ...newDetails}));
            } catch (error) {
                console.error('Error updating user details:', error);
            }
        }
    };

    const value = {
        currentUser,
        userDetails,
        dashboardAccess,
        login,
        register,
        logout,
        verificarIdBO,
        generarIDPT,
        generarIdBOUnico,
        createCentroDocument,
        updateUserDetails
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
