// src/components/clients/ClientList.js
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { 
    Box, 
    Grid, 
    Card, 
    CardActionArea, 
    Typography, 
    Avatar,
    IconButton,
    CircularProgress,
    Chip,
    TextField
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SearchIcon from '@mui/icons-material/Search';
import clientsBackground from '../../assets/images/imagen-dashboard-clients.png';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { AuthContext } from '../../contexts/AuthContext'; // Asegúrate de que la ruta sea correcta
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiChip: {
            variants: [
                {
                    props: { variant: 'pro' },
                    style: {
                        background: 'linear-gradient(45deg, #FFA500 30%, #FF1493 90%)',
                        color: 'white',
                        fontWeight: 'bold',
                    },
                },
            ],
        },
    },
});

const ClientCard = ({ client }) => (
    <ThemeProvider theme={theme}>
        <Card sx={{ display: 'flex', alignItems: 'center', height: '100%', borderRadius: 4, maxWidth: 340 }}>
            <CardActionArea component={Link} to={`/clients/${client.id}`} sx={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Avatar 
                    src={client.photo_url} 
                    alt={`${client.nombre} ${client.apellidos}`}
                    sx={{ width: 70, height: 70, m: 1.5 }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1.5, flexGrow: 1, overflow: 'hidden' }}>
                    <Typography variant="h6" component="div" noWrap>
                        {`${client.nombre} ${client.apellidos}`}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                        {client.email}
                    </Typography>
                    <Chip 
                        label={client.plan === 'Pro' ? 'Pro' : 'Free'}
                        variant={client.plan === 'Pro' ? 'pro' : 'default'}
                        size="small"
                        sx={{ mt: 0.75, alignSelf: 'flex-start' }}
                    />
                </Box>
                <IconButton aria-label="ver detalles" sx={{ mr: 1.5 }}>
                    <ArrowForwardIosIcon />
                </IconButton>
            </CardActionArea>
        </Card>
    </ThemeProvider>
);

const ClientList = () => {
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { userDetails } = useContext(AuthContext);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchClients = async () => {
            const db = getFirestore();
            
            console.log("Tipo de profesional:", userDetails?.tipo_profesional);
            
            if (!userDetails) {
                console.log("No se encontraron detalles del usuario");
                setError("No se ha encontrado un usuario autenticado o detalles del usuario");
                setLoading(false);
                return;
            }

            try {
                let clientsCollectionRef;

                if (userDetails.tipo_profesional === 'entrenador_independiente') {
                    console.log("Accediendo a clientes de entrenador independiente");
                    clientsCollectionRef = collection(db, 'userDetails', userDetails.uid, 'Clientes_asociados');
                } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                    console.log("Accediendo a clientes de centro");
                    if (!userDetails.IDBO) {
                        throw new Error('ID del Centro (IDBO) no disponible');
                    }
                    clientsCollectionRef = collection(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros');
                } else {
                    throw new Error("Tipo de profesional no reconocido");
                }

                console.log("Referencia de colección:", clientsCollectionRef.path);

                const clientsSnapshot = await getDocs(clientsCollectionRef);
                console.log("Número de documentos obtenidos:", clientsSnapshot.size);

                const clientsData = clientsSnapshot.docs.map(doc => {
                    const data = doc.data();
                    const isInSolicitudes = userDetails?.solicitudes?.some(solicitud => solicitud.uid === data.uidcliente) || false;

                    return {
                        id: doc.id,
                        nombre: data.nombre || 'Nombre no disponible',
                        apellidos: data.apellidos || 'Apellido no disponible',
                        email: data.email || 'Email no disponible',
                        photo_url: isInSolicitudes ? '' : (data.photo_url || ''),
                        plan: data.plan || 'Free',
                        isInSolicitudes: isInSolicitudes
                    };
                });

                console.log("Clientes procesados:", clientsData);

                // Filtrar los clientes que no están en solicitudes
                const approvedClients = clientsData.filter(client => !client.isInSolicitudes);

                setClients(approvedClients);
            } catch (error) {
                console.error("Error al obtener los clientes:", error);
                setError("Error al cargar los clientes: " + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchClients();
    }, [userDetails]);

    const filteredClients = clients.filter(client =>
        `${client.nombre} ${client.apellidos}`.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box 
            sx={{ 
                flexGrow: 1, 
                p: 3,
                position: 'relative',
                minHeight: 'calc(100vh - 64px)', // Ajusta este valor según la altura de tu Navbar
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: `url(${clientsBackground})`,
                    backgroundSize: '50%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    opacity: 0.15,
                    zIndex: -1,
                }
            }}
        >
            <Typography variant="h4" gutterBottom>
                Mis Clientes
            </Typography>
            <Typography variant="subtitle1" gutterBottom sx={{ mb: 3 }}>
                Gestiona a tus clientes desde esta página.
            </Typography>
            
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        variant="outlined"
                        size="small"
                        placeholder="Buscar cliente"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: 4,
                                backgroundColor: 'white',
                            }
                        }}
                    />
                    <IconButton sx={{ ml: 1 }}>
                        <SearchIcon />
                    </IconButton>
                </Box>
            </Box>

            <Grid container spacing={3}>
                {filteredClients.map((client) => (
                    <Grid item xs={12} sm={6} md={4} key={client.id}>
                        <ClientCard client={client} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default ClientList;
